import { IEventFormData, IEventUserRegistrationRequest } from "../../contracts";
import { IEventAttendees } from "../../contracts/models";
import BaseHttpAPI from "../base/BaseHttpAPI";

/**
 * Strike Events Registration and Check-In. Used by through ServiceHub.
 */
export class EventsAPIService extends BaseHttpAPI {
	constructor() {
		super("EventRegistration");
	}

	/**
	 * Get Event attendees
	 * @returns attendees
	 */
	async getEventAttendees(): Promise<IEventAttendees[]> {
		const response = await this.get("attendees");
		return response as IEventAttendees[];
	}

	/**
	 * Check-In specific attendees
	 * @returns boolean
	 */
	async checkInAttendee(id: number, email: string, team: string): Promise<boolean> {
		const response = await this.post("attendees/checkIn", { id, email });
		return response as boolean;
	}

	/**
	 * Register and Checkin-In attendee
	 * @param team
	 * @param email
	 * @param id
	 * @returns boolean
	 */
	async registerAndCheckInAttendee(userName: string, userEmail: string, userTeam: string): Promise<boolean> {
		const response = await this.post("attendees/registerAndCheckIn", { userName, userEmail, userTeam });
		return response as boolean;
	}

	/**
	 * Registration for a single user into a specific event
	 * @param eventId
	 * @returns
	 */
	async userEventRegistration(registrationData: IEventUserRegistrationRequest): Promise<boolean> {
		return await this.post<IEventUserRegistrationRequest, boolean>(
			`LogUserEventRegistrationResponse`,
			registrationData
		);
	}

	/**
	 * Get Event Details
	 * @param eventId
	 * @returns
	 */
	async getEvent(eventId: string): Promise<IEventFormData> {
		return await this.get<IEventFormData>(`${eventId}`)
			.then((result) => {
				return result;
			})
			.catch((error) => error);
	}

	/**
	 * Check if User has Volunteer Access
	 * @returns
	 */
	async getVolunteerAccess(eventId: number): Promise<boolean> {
		return await this.get<boolean>(`IsVolunteerUser/${eventId}`)
			.then((result) => {
				return result;
			})
			.catch((error) => false);
	}
}
