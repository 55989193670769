import React, { SyntheticEvent, useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router";
import { CheckboxProps, DropdownOnSearchChangeData, DropdownProps } from "semantic-ui-react";

import { Button } from "../../common/button";
import { DivEventHandler, InputTextEventHandler, RichTextInput, TextInput } from "../../common/text";
import {
	FieldRequiredMark,
	FormActions,
	FormContainer,
	FormField,
	FormHint,
	FormValidationMessage
} from "../../common/form";
import { Dropdown, TreeViewDropdown } from "../../common/dropdown";
import { ApplicationRoutePaths } from "../../router";
import { ComboBox } from "../../common/combobox";
import { ServiceHub } from "../../../service";
import { CoverImageType } from "../../../enums";
import { NodeFormFieldIds, NodeFormLabels, NodeFormPlaceholders } from "./node.form.strings";
import { useNodeForm, useNodeOwner } from "../../../hooks";
import { CoverImageModal, CoverImagePlaceholders } from "../image/gallery";
import { NodeType } from "../../../contracts/models/strikeEnums";
import { UnauthorizedPage } from "../page";
import { ActionPostMessage } from "../message/action.post.message";
import { setIsOpenActionPostMessage } from "../../../redux/reducers/nodes";
import { useDispatch } from "react-redux";
import Constants from "../../../constants/Constants";
import { FormShimmer, Shimmer } from "../shimmer";

/**
 * Contract for the input props of the NodeForm.
 */
export interface INodeFormProps {
	type: NodeType;
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void> | void;
}

/**
 * Re-usable Article creation form
 *
 * @returns React.FC
 */
export const NodeForm: React.FC<INodeFormProps> = (props) => {
	// APIs handled within
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const articleFormService = useRef(ServiceHub.articleForm.start());
	const articleDataAPI = useRef(ServiceHub.articleDataAPI.start());
	const nodeAPI = useRef(ServiceHub.nodeAPI);

	const {
		// Main form state elements
		formData,
		parentArticles,
		addedTopics,
		topics,
		parentSpaces,
		coverImageGalleryOpen,
		// Differentiates between New and Edit modes
		canAccessNodeType,
		canAddTopics,
		isNewArticle,
		enableModerationMessage,
		articleCoverImagePreview,
		bodySourceMode,
		virtualHTML,
		hasError,
		canSubmit,
		validation,
		// Loading control state
		submitting,
		loading,
		loaded,
		queryingSpaces,
		spaces,
		isFieldEnabled
		// setAddedTopics
	} = useNodeForm(props.type);
	const isUserOwner = useNodeOwner();
	const resolvedNodeOwner = isUserOwner({ Id: parseInt(formData.id), AuthEmail: formData.authorUpn });

	const selectedSpaceItem = useMemo(() => {
		if (!formData.spaceId) return null;

		const foundArticle = articleFormService.current.getSpaceById(formData.spaceId);

		if (!foundArticle || foundArticle === null) return null;

		return {
			id: formData.spaceId.toString(),
			text: foundArticle.Title
		};
	}, [formData.spaceId, articleFormService.current, spaces]);

	const submitDisabled = useMemo(() => submitting || loading || !canSubmit, [submitting, loading, canSubmit]);

	const onUpdateParentArticlesList = useCallback(
		async (query: string) => {
			if (!query || query.length === 0 || !validation.spaceId) return;

			return articleDataAPI.current.searchArticles(formData.spaceId, query).then((data) => {
				articleFormService.current.setParentArticlesList(data);
			});
		},
		[articleDataAPI, articleFormService, formData.spaceId, validation.spaceId]
	);

	const onSetStateProp = useCallback(
		function (prop: string, value: any) {
			articleFormService.current.setData({
				...formData,
				[prop]: value
			});
		},
		[articleFormService, formData]
	);

	const onChange = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();

			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget?.value);
		},
		[onSetStateProp]
	);

	const onChangeGeneric = useCallback(
		(prop: string) => (event: React.FocusEvent<HTMLElement>) => {
			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget.innerText);
			// When setting this state, clearing the errors to reprocess them
			virtualHTML.onClearErrors();
		},
		[onSetStateProp, virtualHTML.onClearErrors]
	);

	const onChangeVirtualHtml = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();

			virtualHTML.onChange(typeof event === "string" ? event : event.currentTarget?.value);
		},
		[virtualHTML]
	);

	const onTypeSearchParentArticles = useCallback(
		(event: SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => {
			articleFormService.current.setParentArticleQuery(data.searchQuery);

			if (data.searchQuery && data.searchQuery.length > 0) {
				onUpdateParentArticlesList(data.searchQuery);
			}
		},
		[articleFormService, onUpdateParentArticlesList]
	);

	const onChangeParentArticleSelection = useCallback(
		(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();

			onSetStateProp("parentId", data?.value ?? null);
		},
		[onSetStateProp]
	);

	const onTypeSearchTopics = useCallback(
		(event: SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => {
			articleFormService.current.setTopicsText(data.searchQuery);

			if ((!topics || topics.length === 0) && data.searchQuery.length > 0) {
				nodeAPI.current.getNodesAllTopics().then((result) => {
					articleFormService.current.setTopicsList(result);
				});
			}
		},
		[articleFormService, topics, nodeAPI]
	);

	const onChangeTopicsSelection = useCallback(
		(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();
			let dropdownItems: Set<string>;
			let resultAddedTopics = [];

			if (Array.isArray(data?.value)) {
				dropdownItems = new Set<string>(data?.value?.map((item) => item.toString().toUpperCase()) as string[]);

				// If any removed item should be removed from Added Topics
				// Removes the item, if manually included by the end-user
				if (addedTopics.length > 0 && dropdownItems.entries.length > 0) {
					resultAddedTopics = addedTopics.filter((addedTopic) => dropdownItems.has(addedTopic));
				} else {
					resultAddedTopics = addedTopics;
				}
			} else {
				dropdownItems = new Set<string>([data?.value.toString()]);
			}

			articleFormService.current.setData({
				...formData,
				topics: Array.from(dropdownItems),
				addedTopics: resultAddedTopics
			});
		},
		[articleFormService.current, formData, addedTopics]
	);

	const onAddTopic = useCallback(
		(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();

			const dataValue = !data.value ? "" : data.value.toString().toUpperCase();

			if (!dataValue || addedTopics.includes(dataValue)) {
				return;
			}

			// Adds the topic to the Added list, since it is controlled separately
			articleFormService.current.setData({
				...formData,
				addedTopics: [...addedTopics, dataValue],
				topics: !formData.topics.includes(dataValue) ? [...formData.topics, dataValue] : formData.topics
			});
		},
		[articleFormService.current, addedTopics, formData]
	);

	const onCheck = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
			onSetStateProp(prop, data?.checked);
		},
		[articleFormService, formData]
	);

	function onOpenCoverImageGallery() {
		articleFormService.current.setCoverImageGalleryOpen(true);
	}

	function onCloseCoverImageGallery() {
		articleFormService.current.setCoverImageGalleryOpen(false);
	}

	const onClearSelection = useCallback(
		(prop: string, clearValue: undefined | string | [] = "") => {
			return (event: React.MouseEvent<HTMLAnchorElement>) => {
				onSetStateProp(prop, clearValue);
			};
		},
		[onSetStateProp]
	);

	const onHandleCoverImage = function (event: React.FormEvent<HTMLButtonElement>) {
		onOpenCoverImageGallery();
	};

	const onSelectGalleryCoverImage = useCallback(
		(imageId: string | number, imagePath: string) => {
			articleFormService.current.setData({
				...formData,
				coverImageType: CoverImageType.Gallery,
				coverImageFile: null,
				coverImageMimeType: null,
				coverImageSize: null,
				coverImagePath: imagePath,
				coverImageId: imageId
			});

			onCloseCoverImageGallery();
		},
		[formData, articleFormService]
	);

	const onUploadCoverImage = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const { files } = event.currentTarget;

			if (!files || files.length === 0) return;

			const fileUnit = files[0];
			const reader: FileReader = new FileReader();

			reader.onload = function (this: FileReader, ev: ProgressEvent<FileReader>) {
				articleFormService.current.setData({
					...formData,
					coverImageType: CoverImageType.Upload,
					coverImageFile: reader.result.toString(),
					coverImageMimeType: fileUnit.type,
					coverImageSize: fileUnit.size,
					coverImagePath: fileUnit.name,
					coverImageId: ""
				});

				onCloseCoverImageGallery();
			};

			reader.readAsDataURL(fileUnit);
		},
		[formData, articleFormService]
	);

	const onSelectDefaultCoverImage = useCallback(() => {
		// Use Default Image
		articleFormService.current.setData({
			...formData,
			coverImageType: CoverImageType.Default,
			coverImageFile: null,
			coverImageMimeType: null,
			coverImageSize: null,
			coverImagePath: "",
			coverImageId: ""
		});

		if (coverImageGalleryOpen) onCloseCoverImageGallery();
	}, [formData, articleFormService, coverImageGalleryOpen]);

	/**
	 * Sets the Article Body writing mode,
	 * based on the a currentTarget.value
	 *
	 * @param event
	 */
	function onSetBodySourceMode(event: React.FormEvent<HTMLButtonElement>) {
		const value = event.currentTarget.value === "true";

		articleFormService.current.setBodySourceMode(value);
	}

	/**
	 * Cancels the edit of the form,
	 * resetting states.
	 *
	 * @param event The event triggering the action.
	 * @returns Navigation to another route
	 */
	function onCancel(event: React.FormEvent<HTMLButtonElement>) {
		articleFormService.current.reset();
		articleFormService.current.resetData();
		return navigate(ApplicationRoutePaths.dashboard());
	}

	function onActionPostClose() {
		dispatch(setIsOpenActionPostMessage(false));
	}

	const renderLoading = () => {
		return <FormShimmer />;
	};
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	/**
	 * Prepares the Form Validation
	 * additional properties, including virtualization
	 * steps.
	 */
	const formValidation = useMemo(() => {
		const additionalErrors = virtualHTML.erroredElements;
		const additionalErrorKeys = additionalErrors?.map((error) => error.elementType) ?? [];

		const propNames = [...Object.keys(validation)];
		const propValidation = { ...validation };
		const propMessages = { ...NodeFormLabels.validation };

		if (additionalErrorKeys.length) {
			const additionalErrorsMap = additionalErrors.reduce((acc, error) => {
				acc[error.elementType] = error.error;
				return acc;
			}, {});

			additionalErrorKeys.forEach((addErrorKey) => {
				// When error prop is not yet added
				if (!propNames.includes(addErrorKey)) {
					propNames.push(addErrorKey);
					propValidation[addErrorKey] = false;
					propMessages[addErrorKey] = additionalErrorsMap[addErrorKey];
				}
			});
		}

		return {
			hide: canSubmit,
			propNames,
			propValidation,
			propMessages
		};
	}, [canSubmit, validation, virtualHTML.erroredElements]);

	useLayoutEffect(() => {
		const parentSpacesInputElement = document.querySelector("#parentId input");

		const topicInputElement = document.querySelector("#topics input");

		if (parentSpacesInputElement) {
			parentSpacesInputElement.setAttribute("title", NodeFormPlaceholders[props.type].parent);
		}

		if (topicInputElement) {
			topicInputElement.setAttribute("title", NodeFormPlaceholders[props.type].topics);
		}
	}, [loaded]);

	if ((isNewArticle && canAccessNodeType) || canAccessNodeType || (formData.authorUpn !== "" && resolvedNodeOwner))
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-6 col-xl-8" tabIndex={0} aria-label="Form Page">
					<FormContainer
						onSubmit={props?.onSubmit ?? undefined}
						title={
							!loaded || loading ? (
								<Shimmer height={20} width={100} />
							) : isNewArticle ? (
								NodeFormLabels.top[props.type].pageTitleCreate
							) : (
								NodeFormLabels.top[props.type].pageTitleEdit
							)
						}
						useModerationAlert={loaded && !loading && enableModerationMessage}
					>
						{!loaded || loading ? (
							renderLoading()
						) : (
							<>
								{isFieldEnabled(NodeFormFieldIds.space) ? (
									<FormField
										id={NodeFormFieldIds.space}
										label={NodeFormLabels.space}
										onClear={onClearSelection("spaceId", "")}
										value={formData.spaceId}
										required
									>
										<TreeViewDropdown
											id={NodeFormFieldIds.space}
											options={parentSpaces}
											placeholder={NodeFormPlaceholders[props.type].space}
											onItemToggled={(item, isExpanded) => {
												articleFormService.current.setExpandedSpaceById(
													parseInt(item.id),
													isExpanded
												);
											}}
											selectedItem={selectedSpaceItem}
											disabled={queryingSpaces}
											error={formData.spaceId === "" && hasError}
										/>
									</FormField>
								) : null}
								{isFieldEnabled(NodeFormFieldIds.parent) ? (
									<FormField
										id={NodeFormFieldIds.parent}
										label={NodeFormLabels.parent}
										onClear={onClearSelection("parentId", null)}
										value={formData.parentId}
									>
										<ComboBox
											id={NodeFormFieldIds.parent}
											placeholder={NodeFormPlaceholders[props.type].parent}
											options={parentArticles}
											selectedValue={formData?.parentId}
											onChange={onChangeParentArticleSelection}
											onType={onTypeSearchParentArticles}
											disabled={!validation.spaceId}
											ariaLabel={"Select a Space from the Tree View"}
										/>
									</FormField>
								) : null}
								{isFieldEnabled(NodeFormFieldIds.title) ? (
									<FormField id={NodeFormFieldIds.title} label={NodeFormLabels.title} required>
										<TextInput
											id={NodeFormFieldIds.title}
											placeholder={NodeFormPlaceholders[props.type].title}
											onChange={onChange("title")}
											value={formData.title}
											required
											error={NodeFormFieldIds.title === "" && hasError}
											maxLength={200}
										/>
									</FormField>
								) : null}
								{isFieldEnabled(NodeFormFieldIds.body) ? (
									<FormField id={NodeFormFieldIds.body} required>
										<div className="form-field-header">
											<label htmlFor={NodeFormFieldIds.body} aria-label={NodeFormLabels.body}>
												{NodeFormLabels.body} <FieldRequiredMark />
											</label>
											<div className="button-group">
												<button
													type="button"
													onClick={onSetBodySourceMode}
													value={"false"}
													className={`${!bodySourceMode ? "selected" : ""}`}
												>
													{NodeFormLabels.actions.previewMode}
												</button>
												<button
													type="button"
													onClick={onSetBodySourceMode}
													value={"true"}
													className={`${bodySourceMode ? "selected" : ""}`}
												>
													{NodeFormLabels.actions.sourceMode}
												</button>
											</div>
										</div>
										<div className="form-control">
											<RichTextInput
												id={NodeFormFieldIds.body}
												placeholder={
													bodySourceMode
														? NodeFormPlaceholders[props.type].bodySourceMode
														: NodeFormPlaceholders[props.type].body
												}
												onChange={
													bodySourceMode
														? (onChangeGeneric("body") as DivEventHandler)
														: (onChangeVirtualHtml("body") as InputTextEventHandler)
												}
												value={
													!bodySourceMode ? virtualHTML.virtualHtml : virtualHTML.originalHtml
												}
												sourceMode={bodySourceMode}
												error={NodeFormFieldIds.body === "" && hasError}
												onBlur={(event) =>
													virtualHTML.onSyncOriginal(
														event.currentTarget.innerHTML,
														(newContent: string) => {
															onSetStateProp("body", newContent);
														}
													)
												}
											/>
										</div>
										<FormHint>
											{NodeFormLabels.hints.map((hint, index) => (
												<div key={index}>{hint}</div>
											))}
										</FormHint>
									</FormField>
								) : null}
								{isFieldEnabled(NodeFormFieldIds.topics) ? (
									<FormField
										id={NodeFormFieldIds.topics}
										label={NodeFormLabels.topics}
										onClear={onClearSelection("topics", [])}
										value={formData.topics}
									>
										<Dropdown
											id={NodeFormFieldIds.topics}
											placeholder={NodeFormPlaceholders[props.type].topics}
											width={16}
											fluid
											search
											selection
											multiSelect
											className="form-control"
											selectedValue={formData.topics}
											options={topics}
											onChange={onChangeTopicsSelection}
											onSearchChange={onTypeSearchTopics}
											onAddItem={onAddTopic}
											allowAdditions={canAddTopics}
											closeOnBlur
											closeOnEscape
											skipTabIndexing
										/>
									</FormField>
								) : null}
								{isFieldEnabled(NodeFormFieldIds.selectCover) ? (
									<FormField id={NodeFormFieldIds.selectCover}>
										<Button
											text={NodeFormLabels.actions.selectCover}
											onClick={onHandleCoverImage}
										/>
										{coverImageGalleryOpen ? (
											<CoverImageModal
												open={coverImageGalleryOpen}
												onClose={onCloseCoverImageGallery}
												selectedImage={formData.coverImagePath}
												onSelectFromGallery={onSelectGalleryCoverImage}
												onDefault={onSelectDefaultCoverImage}
												onUpload={onUploadCoverImage}
											/>
										) : null}
									</FormField>
								) : null}
								{isFieldEnabled(NodeFormFieldIds.selectedCoverView) ? (
									<FormField
										id={NodeFormFieldIds.selectedCoverView}
										label={NodeFormLabels.selectedCoverView}
										onClear={onSelectDefaultCoverImage}
										value={formData.coverImagePath}
									>
										<div className="form-image-view">
											<img
												src={
													!articleCoverImagePreview
														? CoverImagePlaceholders.defaultCoverImageUrl
														: articleCoverImagePreview
												}
												alt={NodeFormLabels.selectedCoverView}
												title={NodeFormLabels.selectedCoverView}
												tabIndex={0}
												aria-label={`Selected Cover Image: ${NodeFormLabels.selectedCoverView}`}
												role="img"
											/>
										</div>
									</FormField>
								) : null}
								{/* {isFieldEnabled(NodeFormFieldIds.private) ? (
							<FormField id={NodeFormFieldIds.private}>
								<Checkbox
									className="form-control"
									id={NodeFormFieldIds.private}
									label={NodeFormLabels.private[props.type]}
									onChange={onCheck("private")}
									checked={formData.private}
								/>
							</FormField>
						) : null} */}
								<FormField id="validationMessage">
									<FormValidationMessage {...formValidation} />
								</FormField>
								<FormActions
									actions={[
										{
											type: "submit",
											text: NodeFormLabels.actions.submit,
											disabled: submitDisabled
										},
										{
											type: "button",
											variation: "secondary",
											className: "cancel",
											text: NodeFormLabels.actions.cancel,
											onClick: onCancel
										}
									]}
								/>
							</>
						)}
					</FormContainer>
				</div>
				<div className="col-md-12 col-lg-6 col-xl-4">
					<div className="form-info-text" tabIndex={0}>
						<h3 className="header" tabIndex={0}>
							Remember To…
						</h3>
						<h4 tabIndex={0}>Keep it relevant.</h4>
						<p tabIndex={0}>
							Make sure you've selected the correct space to post in so your content is seen by the
							appropriate audience.
						</p>
						<h4 tabIndex={0}>Keep it clear and concise.</h4>
						<p tabIndex={0}>
							Provide enough details so that other users will be able to better help and engage with you.
						</p>
						<h4 tabIndex={0}>Avoid duplicate posts.</h4>
						<p tabIndex={0}>
							Search for your questions or content to see if any answers or related posts have already
							been provided.
						</p>
						<h4 tabIndex={0}>Be polite.</h4>
						<p tabIndex={0}>
							Refrain from using unnecessary formatting or bad language and be respectful of others in the
							community.
						</p>
						<h4 tabIndex={0}>Embedded content.</h4>
						<p tabIndex={0}>
							You would better experience with <b>&lt;iframe&gt;</b>, <b>&lt;embed&gt;</b>, or&nbsp;
							<b>&lt;video&gt;</b>, by using Source Edit mode tab.
						</p>
					</div>
				</div>
				<ActionPostMessage
					onClose={onActionPostClose}
					title={Constants.postedConfirmationTexts.ArticlePosted}
					text={Constants.postedConfirmationTexts.ArticlePostedText}
				/>
			</div>
		);

	if (
		loaded &&
		!loading &&
		!canAccessNodeType &&
		(isNewArticle ||
			(formData.authorUpn !== "" && !isUserOwner({ Id: parseInt(formData.id), AuthEmail: formData.authorUpn })))
	) {
		return renderUnauthorized();
	}
};
