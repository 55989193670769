/**
 * Redux config to manage follow component state
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { followInitialState } from "../../models/slices";
import { IFollowRelation } from "../../contracts";

/**
 * Node Comments Management state layer, also named after a Redux Slice.
 *
 */
export const followSlice = createSlice({
	name: AppSlices.follow,
	initialState: followInitialState,
	reducers: {
		setFollowers: (state, action: PayloadAction<IFollowRelation[]>) => {
			state.data.followers = action.payload;
		},
		resetFollowers: (state) => {
			state.data.followers = followInitialState.data.followers;
		},
		setQuerying: (state, action: PayloadAction<boolean>) => {
			state.ui.querying = action.payload;
		},
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.ui.queried = action.payload;
		},
		setFollowersToShow: (state, action: PayloadAction<number>) => {
			state.ui.followersToShow = action.payload;
		},
		resetFollowersToShow: (state) => {
			state.ui.followersToShow = followInitialState.ui.followersToShow;
		},
		addFollower: (state, action: PayloadAction<IFollowRelation>) => {
			if (!state.data.followers.some((item) => item.UserUPN === action.payload.UserUPN))
				state.data.followers.push(action.payload);
		},
		removeFollower: (state, action: PayloadAction<number | string>) => {
			state.data.followers = state.data.followers.filter((item) => {
				return item.UserUPN !== action.payload;
			});
		},
		resetAll: (state) => {
			state.data = followInitialState.data;
			state.ui = followInitialState.ui;
		},
		setIsFavourite: (state, action: PayloadAction<boolean>) => {
			state.ui.isFavourite = action.payload;
		},
	}
});

export const {
	setFollowersToShow,
	resetFollowersToShow,
	setFollowers,
	resetFollowers,
	setQuerying,
	setQueried,
	addFollower,
	removeFollower,
	resetAll,
	setIsFavourite
} = followSlice.actions;

export default followSlice.reducer;
