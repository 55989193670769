import React, { useMemo, useEffect } from "react";
import { UserListBoxPhoto } from "./userList.photo";
import followPaginationConstants from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { followSelectors } from "../../../../redux/selectors/follow";
import { setFollowersToShow } from "../../../../redux/reducers/follow";
import { useRef } from "react";
import { ServiceHub } from "../../../../service";
import { useAbortController, useDownloadQueue } from "../../../../hooks";

/**
 * Controls the User List Box Content input props.
 */
export interface IUserListBoxContentProps {
	usersUpn: string[];
	hideEmptyRecordsMessage?: undefined | boolean;
}

/**
 * User List Box Content component.
 *
 * @param props IUserListBoxContentProps
 * @returns React.FC<IUserListBoxContentProps>
 */
export const UserListBoxContent: React.FC<IUserListBoxContentProps> = (props) => {
	const followAPI = useRef(ServiceHub.followAPI.start());
	const followersToShow = useSelector(followSelectors.getFollowersToShow);
	const dispatch = useDispatch();
	const downloadQueue = useDownloadQueue<any>();
	const abortController = useAbortController();
	const renderUserPhoto = (userUpn: string, index: number) => (
		<UserListBoxPhoto key={index} userUpn={userUpn} userName={followAPI.current.getFollowerNameByUpn(userUpn)} />
	);

	/**
	 * Memo to manage rendering photos if there is available Followers
	 */
	const memoRenderUserProfiles = useMemo(() => {
		return props?.usersUpn.length > 0
			? props.usersUpn.slice(0, Math.min(followersToShow, props.usersUpn.length)).map(renderUserPhoto) // split-test scenario
			: // ? props.usersUpn.map(renderUserPhoto) // prop-scenario
			props?.hideEmptyRecordsMessage
			? ""
			: "No records";
	}, [props?.usersUpn, props?.hideEmptyRecordsMessage, followersToShow]);

	/**
	 * Memo of number of followers to display tracked by the folowersToShow variable by user interaction.
	 */
	const followersUpnSlice = useMemo(() => {
		return props?.usersUpn.length > 0
			? props.usersUpn.slice(0, Math.min(followersToShow, props.usersUpn.length))
			: [];
	}, [props?.usersUpn, followersToShow]);

	/**
	 * Calling the specific Followers photo method voa ServiceHub
	 * @param upn
	 * @returns
	 */
	const loadUPNImage = async (upn: string) => {
		return ServiceHub.userProfileAPI
			.start()
			.loadFollowersProfilePictures(upn, abortController.abortController ?? undefined);
	};

	/**
	 * Tracking if there is followersUpn to add to the queue and download image
	 * and only process the Queue if status its not processing.
	 */
	useEffect(() => {
		if (followersUpnSlice.length > 0) {
			followersUpnSlice?.forEach((upn) => {
				downloadQueue.enqueue(async () => loadUPNImage(upn));
			});
		}
		if (!downloadQueue.isProcessing()) {
			downloadQueue.processQueue();
		}
	}, [downloadQueue, followersUpnSlice]);

	const loadMoreFollowers = () => {
		dispatch(
			setFollowersToShow(
				props.usersUpn.length <= followersToShow
					? props.usersUpn.length
					: followersToShow + followPaginationConstants.paginationOffset
			)
		);
	};

	return (
		<>
			{memoRenderUserProfiles}
			{props.usersUpn.length > followersToShow ? (
				<div className="user-list-content-load-more">
					<button onClick={loadMoreFollowers}>Load More</button>
				</div>
			) : null}
		</>
	);
};
