import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Divider, Segment } from "semantic-ui-react";

import { NodeActionTypes, NodeType, TitleType } from "../../contracts/models/strikeEnums";
import { useAuthUser } from "../../hooks/auth/useAuthUser";
import {
	activityUser,
	createAuthoriableUser,
	getQueryCreateAuthUser,
	getQueryUserActivityObject,
	renderNodePdf,
	validateEmail
} from "../../lib/strikeLibrary";
import StrikeNode from "../../models/StrikeNode";
import { setAllNodeComments, setNodeLikeByUsers, setNodeLikeCount } from "../../redux/reducers/comments";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import PageHeader from "../Header/page.header";
import { AuthorMention } from "../common/article";
import { NodeActivity, RichTextNode } from "../common/node";
import { QuestionShimmer } from "../common/shimmer/question/shimmer.question";
import { ShowAnswers } from "./show.question.answers";
import { ApplicationRoutePaths } from "../router";
import { ServiceHub } from "../../service";
import { IStrikeNodeEntity } from "../../contracts/models";
import { FollowType } from "../../enums";
import { FollowerSection } from "../common/follower";
import { ConfirmationMessage } from "../common/message";
import { useNodeActionsSlice } from "../../hooks/node/useNodeActions";
import {
	setDeleteConfirmationModalButtonText,
	setDeleteConfirmationModalSubText,
	setDeleteConfirmationModalTitle,
	setIsActionConfirmed,
	setIsOpenDeleteConfirmation,
	setIsOpenPrivateConfirmation
} from "../../redux/reducers/nodes";
import Constants from "../../constants/Constants";
import { CoverDisplayImage } from "../common/image";
import { ArticleImgShimmer, TopicsShimmer } from "../common/shimmer";
import { useNodeOwner } from "../../hooks";
import { NodeActivityMainCommentForm } from "../common/node/activity/nodeActivity.main.comment.form";
import { ShowEditTags } from "../common/tags/showEditTags";
import { setTagsIds, setTagsNames } from "../../redux/reducers/tags";
import { CustomPanel } from "../common/panel/Panel";
import { NodeChangeSpaceForm } from "../common/node/space/nodeChangeSpace";
import { Breadcrumbs } from "../common/breadcrumb/breadcrumb";
import { ShareContentPanel } from "../common/share";
import { nodesSelectors } from "../../redux/selectors/nodes";
import { NodeRevisions } from "../common/node/revisions/node.revisions";
import { NodeReportPanel } from "../common/node/report/node.report";

/**
 * Controls the input props of Show Question.
 */
interface IShowQuestionProps {}

/**
 * Question viewer component.
 *
 * @param props IShowQuestionProps
 * @returns React.FunctionComponent<IShowQuestionProps>
 */
export const ShowQuestion: React.FunctionComponent<IShowQuestionProps> = (props) => {
	const params = useParams();
	const dispatch = useDispatch();
	const reportService = ServiceHub.commentAPI.start();
	const [nodeDetails, setNodeDetails] = useState<IStrikeNodeEntity>(new StrikeNode(0, "", ""));
	const [nodeId, setNodeId] = useState("");
	const [isLoadingNodes, setIsLoadingNodes] = useState(false);
	const [isLoadedNodes, setIsLoadedNodes] = useState(false);
	const { userPrincipalName, userDisplayName, userId } = useAuthUser();
	const ipAddress = "";
	const NodeActions = useNodeActionsSlice();
	const isOpenDeleteConfirmation = NodeActions.isOpenDeleteConfirmation;
	const isActionConfirmed = NodeActions.isActionConfirmed;
	const isChangeSpacePanelOpen = NodeActions.isChangeSpacePanelOpen;
	const deleteConfirmationTitle = NodeActions.confirmationModalTitle;
	const deleteConfirmationSubText = NodeActions.confirmationModalSubText;
	const isPrivateStatus = useSelector(nodesSelectors.getPrivateStatus);
	const isDeletedStatus = useSelector(nodesSelectors.getDeletedStatus);
	const isOpenPrivateConfirmation = NodeActions.isOpenPrivateConfirmation;
	const [privateConfirmationTitle, setPrivateConfirmationTitle] = useState("");
	const [privateConfirmationSubTitle, setPrivateConfirmationSubTitle] = useState("");
	const favouriteAPI = React.useRef(ServiceHub.followAPI.start());
	const isRevisionsPanelOpen = NodeActions.isRevisionsPanelOpen;
	const openRevisionsPanel = NodeActions.openRevisionsPanel;
	const dismissRevisionsPanel = NodeActions.dismissRevisionsPanel;

	const openChangeSpacePanel = NodeActions.openChangeSpacePanel;
	const dismissChangeSpacePanel = NodeActions.dismissChangeSpacePanel;

	const isNodeOwner = useNodeOwner();
	const resolvedNodeOwner = isNodeOwner({ Id: nodeDetails.Id, AuthEmail: nodeDetails.AuthEmail });
	const [isOpenReportCommentConfirmation, setIsOpenReportCommentConfirmation] = useState(false);
	const [isOpenSendModerationConfirmation, setIsOpenSendModerationConfirmation] = useState<boolean>(false);

	useEffect(() => {
		async function onLoad() {
			if (!isLoadingNodes && (!isLoadedNodes || params.id != nodeId)) {
				if (Number(params.id) > 0) {
					setIsLoadingNodes(true);
					setNodeId(params.id);
					await getNodeDetails();
					setIsLoadingNodes(false);
					setIsLoadedNodes(true);
				}
			}
			if (validateEmail(userPrincipalName) && params.id) {
				let logActivity = getQueryUserActivityObject(
					NodeActionTypes.NodeView,
					ipAddress,
					params.id,
					userPrincipalName
				);
				let authoriableUser = getQueryCreateAuthUser(userId, userDisplayName, userPrincipalName);
				activityUser(logActivity);
				createAuthoriableUser(authoriableUser);
			}
		}

		onLoad();
	}, [params.id, nodeId, userId, userDisplayName, userPrincipalName, isLoadingNodes, isLoadedNodes]);

	const getNodeDetails = async () => {
		let res = await NodeAPIServiceInstance.getById(Number(params.id));
		if (res.Id) {
			setNodeDetails(res);
			dispatch(setNodeLikeCount(res.LikeCount));
			dispatch(setNodeLikeByUsers(res.LikeByUsers));
			getNodeComments();
			getNodeTopics();
			favouriteAPI.current.setIsFavourite(res.IsFavourite);
			ServiceHub.appManagementAPI.setNodePrivateSiteStatus(res.IsPrivate);
			ServiceHub.appManagementAPI.setNodeDeletedStatus(res.IsDeleted);
		}
	};

	/**
	 * Function to get node comments for a specific nodeId
	 * using the API Service call
	 * Response setup in local state
	 */
	const getNodeComments = async () => {
		let res = await NodeAPIServiceInstance.getNodeComments(Number(params.id));
		if (res) {
			dispatch(setAllNodeComments(res));
		}
	};

	/**
	 * Function to get node Topics for a specific nodeId
	 * using the API Service call
	 */
	const getNodeTopics = async () => {
		let res = await NodeAPIServiceInstance.getNodeTopics(Number(params.id));
		if (res) {
			dispatch(setTagsIds(res.TopicId.split(",")));
			dispatch(setTagsNames(res.TopicName.split(",")));
		}
	};

	function basicEvent(): React.ReactNode {
		return nodeDetails.Title ? <RichTextNode>{nodeDetails.Body}</RichTextNode> : null;
	}

	/**
	 *
	 * @returns The img which will be displayed in the top of the treeView, if the Article has his own or should display Default
	 */
	function renderCoverImage(): React.ReactNode {
		return <CoverDisplayImage node={nodeDetails} loading={isLoadingNodes} noShimmer />;
	}

	/**
	 * Redirecting user to edit form once called from node settings
	 */
	function onEdit() {
		const route = ApplicationRoutePaths.questionEdit(nodeId);
		ServiceHub.appManagementAPI.navigate(route);
	}

	/**
	 * Method called from node settings from Delete or Undelete buttons
	 */
	function onDelete() {
		dispatch(setIsOpenDeleteConfirmation(true));
		dispatch(setIsActionConfirmed(false));
		if (!isDeletedStatus) {
			dispatch(setDeleteConfirmationModalTitle(Constants.deleteConfirmationTexts.DeleteQuestionTitle));
			dispatch(setDeleteConfirmationModalSubText(Constants.deleteConfirmationTexts.DeleteQuestionSubText));
			dispatch(setDeleteConfirmationModalButtonText(Constants.deleteConfirmationTexts.DeleteText));
			ServiceHub.appManagementAPI.setConfirmationCallback(onDeleteConfirmation);
		} else {
			dispatch(setDeleteConfirmationModalTitle(Constants.undeleteConfirmationTexts.UndeleteQuestionTitle));
			dispatch(setDeleteConfirmationModalSubText(Constants.undeleteConfirmationTexts.UndeleteQuestionSubText));
			dispatch(setDeleteConfirmationModalButtonText(Constants.undeleteConfirmationTexts.UndeleteText));
			ServiceHub.appManagementAPI.setConfirmationCallback(onUndeleteConfirmation);
		}
	}

	/**
	 * Passing params to api to delete Question
	 */
	function onDeleteConfirmation() {
		NodeActions.onDeleteConfirmation(nodeId, NodeActionTypes.Delete, userPrincipalName);
	}

	/**
	 * Passing params to api to undelete Question
	 */
	function onUndeleteConfirmation() {
		NodeActions.onUndeleteConfirmation(nodeId, NodeActionTypes.UnDelete, userPrincipalName);
	}

	/**
	 * method to pass to header action to trigger
	 * and open Node Revisions Panel
	 */
	function onNodeRevision() {
		NodeActions.onGetNodeRevisions(nodeId);
		openRevisionsPanel();
	}

	/**
	 * Getting a fresh update of node topics list
	 * after save edit.
	 */
	function onTopicSaveComplete() {
		getNodeTopics();
	}

	/**
	 * method to pass to header action to trigger
	 * and open Change Space Panel
	 */
	function onChangeSpace() {
		openChangeSpacePanel();
	}

	/**
	 * method to call html to pdf library 
	 * and export node content to pdf
	 */
	function onExportToPdf() {
		renderNodePdf('Question', nodeId);
	}

	/**
	 * Call API to set node as private
	 */
	function makeNodePrivate() {
		NodeActions.onMakeNodePrivate(nodeId, NodeActionTypes.MakePrivate, userPrincipalName, NodeType.Question);
	}

	/**
	 * Call API to set node as public
	 */
	function makeNodePublic() {
		NodeActions.onMakeNodePublic(nodeId, NodeActionTypes.MakePublic, userPrincipalName, NodeType.Question);
	}

	/**
	 * Method to switch between Private/Public api calls and content set to modal
	 */
	function onMakeNodePrivateToggle() {
		dispatch(setIsOpenPrivateConfirmation(true));
		if (!isPrivateStatus) {
			setPrivateConfirmationTitle(Constants.privateConfirmationTexts.makeQuestionPrivateTitle);
			setPrivateConfirmationSubTitle(Constants.privateConfirmationTexts.makeQuestionPrivateSubTitle);
			ServiceHub.appManagementAPI.setConfirmationCallback(makeNodePrivate);
		} else {
			setPrivateConfirmationTitle(Constants.privateConfirmationTexts.makeQuestionPublicTitle);
			setPrivateConfirmationSubTitle(Constants.privateConfirmationTexts.makeQuestionPublicSubTitle);
			ServiceHub.appManagementAPI.setConfirmationCallback(makeNodePublic);
		}
	}

	function onReportContent(){
		setIsOpenReportCommentConfirmation(true)
		ServiceHub.appManagementAPI.resetReportReasonValueId();
	}

	/**
	 * Handling Close action for Report Question Panel
	 */
	const onCloseReportCommentConfirmation = () => {
		setIsOpenReportCommentConfirmation(false)
	}

	/**
	 * Main method to hit api to send article to moderation 
	 */
	function sendModerationNode() {
		const nodeUrl = window.location.toString();
		const reportQueryObject = reportService.getQueryObjectToReportComment(parseInt(nodeId), parseInt(nodeId),nodeUrl, userPrincipalName, NodeActionTypes.ModerationRequest);

		reportService.reportComment(reportQueryObject).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
		})
	}

	/**
	 * Handling Send Node To Moderation 
	 */
	function onSendNodeToModeration(){
		setIsOpenSendModerationConfirmation(true);
		ServiceHub.appManagementAPI.setConfirmationCallback(sendModerationNode);
	}

	/**
	 * Closing Send To Modration confirmation modal
	 */
	const onCloseSendModerationConfirmation = () => {
		setIsOpenSendModerationConfirmation(false);
	}

	return (
		<React.Fragment>
			<Segment basic className="strike-node-wrapper">
				<div className="row col-md-12 mb-3">
					<Breadcrumbs nodeId={nodeDetails.Id} />
				</div>
				<div className="row question-view">
					<div className="col-md-8">
						<React.Fragment>
							<PageHeader
								Title={nodeDetails.Title}
								Type={TitleType.Question}
								onEdit={onEdit}
								onDelete={onDelete}
								onChangeSpace={onChangeSpace}
								onMakePrivate={onMakeNodePrivateToggle}
								onExportToPdf={onExportToPdf}
								onNodeRevision={onNodeRevision}
								onReportContent={onReportContent}
								onSendNodeToModeration={onSendNodeToModeration}
								showActions={isLoadedNodes}
								isOwner={resolvedNodeOwner}
								enableShareContent
							/>
							{nodeDetails.Id ? (
								<>
									<ShowEditTags
										nodeId={params?.id}
										type={NodeType.Question}
										authorUpn={nodeDetails.AuthEmail}
										onComplete={onTopicSaveComplete}
									/>
									<Divider />
								</>
							) : (
								<TopicsShimmer />
							)}
							<AuthorMention
								authEmail={nodeDetails.AuthEmail}
								authName={nodeDetails.AuthName}
								createdOn={nodeDetails.CreatedOn}
							/>
							<div className="strike-question">
								{nodeDetails.Id ? (
									<>
										{basicEvent()}
										<NodeActivity nodeId={nodeDetails.Id} type={NodeType.Question} />
										<ShowAnswers nodeId={nodeDetails.Id} />
										<NodeActivityMainCommentForm nodeId={nodeDetails.Id} type={NodeType.Question} />
									</>
								) : (
									<QuestionShimmer />
								)}
							</div>
						</React.Fragment>
						<ConfirmationMessage
							isOpen={isOpenDeleteConfirmation}
							isActionConfirmed={isActionConfirmed}
							onClose={NodeActions.onClose}
							onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
							title={deleteConfirmationTitle}
							text={deleteConfirmationSubText}
						/>
					</div>
					<div className="col-md-4">
						{nodeDetails.Id ? renderCoverImage() : <ArticleImgShimmer />}
						<FollowerSection
							title="Follow this Question"
							nodeId={params?.id}
							type={FollowType.Node}
							customNodeTypeName="question"
						/>
					</div>
				</div>
				<ConfirmationMessage
					isOpen={isOpenPrivateConfirmation}
					onClose={NodeActions.onClosePrivateConfirmation}
					onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
					title={privateConfirmationTitle}
					text={privateConfirmationSubTitle}
				/>
				<ConfirmationMessage
					isOpen={isOpenSendModerationConfirmation}
					onClose={onCloseSendModerationConfirmation}
					onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
					title="Send to Moderation"
					text="Are you sure you want to send this Question to moderation?"
					confirmationText="Yes"
					dismissText="No"
				/>
				<CustomPanel
					isOpen={isChangeSpacePanelOpen}
					onDismiss={dismissChangeSpacePanel}
					headerText="Change Space"
				>
					<NodeChangeSpaceForm nodeTitle={nodeDetails.Title} type={NodeType.Question} />
				</CustomPanel>
				<CustomPanel
					isOpen={isRevisionsPanelOpen}
					onDismiss={dismissRevisionsPanel}
					headerText="Revisions"
				>
					<NodeRevisions />
				</CustomPanel>
				<CustomPanel
					isOpen={isOpenReportCommentConfirmation}
					onDismiss={onCloseReportCommentConfirmation}
					headerText="Report Question"
					>
					<NodeReportPanel NodeId={parseInt(nodeId)} setOpenReportPanel={setIsOpenReportCommentConfirmation} />
				</CustomPanel>
				<ShareContentPanel nodeType={NodeType.Question} formTitle={nodeDetails.Title} />
				<div className="export-to-pdf-template" id="export-to-pdf-template">
					<h2>{nodeDetails.Title}</h2>
					<AuthorMention
						authEmail={nodeDetails.AuthEmail}
						authName={nodeDetails.AuthName}
						createdOn={nodeDetails.CreatedOn}
					/>
					<div className="strike-question">
						<>
							<div className="strike-question-content">{basicEvent()}</div>
							<ShowAnswers nodeId={nodeDetails.Id} />
						</>
					</div>
				</div>
			</Segment>
		</React.Fragment>
	);
};
