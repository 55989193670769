import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ITreeItem } from "@coherence-design-system/tree-view";
import { useSelector } from "react-redux";
import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { DropdownItemProps } from "semantic-ui-react";
import { useParams } from "react-router-dom";

import store from "../../redux/store";
import { articleFormInitialState } from "../../models/slices";
import { articleFormSelectors } from "../../redux/selectors/articleForm";
import { ServiceHub } from "../../service";
import { CoverImageType } from "../../enums";
import { IArticleFormData } from "../../contracts/article/articleForm";
import { ISpaceItem } from "../../contracts";
import { NodeType } from "../../contracts/models/strikeEnums";
import { NodeFormFieldIds } from "../../components/common/node";
import { ApplicationRoutePaths } from "../../components/router";
import { IStrikeNodeEntity } from "../../contracts/models";
import { useNodeAdminAccessControl } from "../auth/useNodeAdminAccessControl";
import { useNodeOwner } from "./useNodeOwner";
import { IHtmlVirtualizationHook, useHtmlVirtualization } from "../html/useHtmlVirtualization";

/**
 * Node Form Validation properties shape
 */
export interface INodeFormValidation {
	[key: string]: boolean;
	spaceId: boolean;
	title: boolean;
	body: boolean;
}

/**
 * Holds the return type contract for the Node Form hook.
 */
export interface IUseNodeFormHook {
	loadData: () => Promise<void>;
	formData: IArticleFormData;
	parentArticles: IComboBoxOption[];
	addedTopics: string[];
	topics: DropdownItemProps[];
	parentSpaces: ITreeItem[];
	spaces: ISpaceItem[];
	coverImageTypes: IDropdownOption<any>[];
	parentArticleQuery: string;
	topicsText: string;
	articleCoverImagePreview: string;
	enabledFields: string[];
	// Boolean states
	canAccessNodeType: boolean;
	canAddTopics: boolean;
	coverImageGalleryOpen: boolean;
	enableModerationMessage: boolean;
	isNewArticle: boolean;
	bodySourceMode: boolean;
	virtualHTML: IHtmlVirtualizationHook;
	hasError: boolean;
	submitting: boolean;
	loading: boolean;
	loaded: boolean;
	canSubmit: boolean;
	validation: INodeFormValidation;
	// Querying states
	queryingSpaces: boolean;
	// Queried states
	// Behaviors
	getChildrenSpaces: (parentId: number | string) => ITreeItem[] | Promise<ITreeItem[]>;
	setHasError: (error: boolean) => void | Promise<void>;
	isFieldEnabled: (fieldId: string) => boolean;
}

/**
 * Re-usable Node creation form state selectors,
 * manager, and function provider hook.
 */
export const useNodeForm = (formType: NodeType): IUseNodeFormHook => {
	// External APIs
	const params = useParams();
	// Service refs
	const articleFormService = useRef(ServiceHub.articleForm.start());
	const articleDataAPI = useRef(ServiceHub.articleDataAPI.start());
	const nodeAPI = useRef(ServiceHub.nodeAPI);
	const previousImagePath = useRef("");
	const nodeAdminAccessControl = useNodeAdminAccessControl();
	const [hasError, setHasError] = useState(false);
	const [coverImageRender, setCoverImageRender] = useState("");

	// Main form state elements
	const formData = useSelector(articleFormSelectors.getData);
	const parentArticles = useSelector(articleFormSelectors.getParentArticleComboBoxOptions);
	const topics = useSelector((state) => articleFormSelectors.getTopicsComboBoxOptions(state, formData.addedTopics));
	const spaces = useSelector(articleFormSelectors.getSpacesList);
	const parentSpaces = useSelector(articleFormSelectors.getParentSpacesDropdownOptions);
	const coverImageTypes = useSelector(articleFormSelectors.getCoverImageTypesDropdownOptions);
	const coverImageGalleryOpen = useSelector(articleFormSelectors.getCoverImageGalleryOpen);
	const parentArticleQuery = useSelector(articleFormSelectors.getParentArticleQuery);
	const topicsText = useSelector(articleFormSelectors.getTopicsText);
	const bodySourceMode = useSelector(articleFormSelectors.getBodySourceMode);
	const submitting = useSelector(articleFormSelectors.getSubmitting);
	const loading = useSelector(articleFormSelectors.getLoading);
	const loaded = useSelector(articleFormSelectors.getLoaded);

	const virtualHTML = useHtmlVirtualization(formData.body);
	const isUserOwner = useNodeOwner();
	const resolvedNodeOwner = isUserOwner({
		Id: !formData.id ? 0 : parseInt(formData.id),
		AuthEmail: formData.authorUpn
	});
	const validation = useMemo<INodeFormValidation>(() => {
		return {
			spaceId: formData.spaceId !== null && formData.spaceId !== 0 && formData.spaceId !== "",
			title: formData.title !== "",
			body: formData.body !== ""
		};
	}, [formData]);
	const canSubmit = useMemo(
		() => validation.body && validation.title && validation.spaceId && virtualHTML.erroredElements.length === 0,
		[validation, virtualHTML.erroredElements]
	);
	const canAddTopics = useMemo(() => {
		return formType === NodeType.Kbentry && (nodeAdminAccessControl.canAccess || resolvedNodeOwner);
	}, [formType, nodeAdminAccessControl, resolvedNodeOwner]);

	/**
	 * Loading control state
	 */
	const queryingSpaces = useSelector(articleFormSelectors.getQueryingSpaces);

	const getChildrenSpaces = useCallback(
		(parentId: number | string) => {
			return articleFormSelectors.getChildrenSpacesDropdownOptions(store.getState(), parentId);
		},
		[formData]
	);

	/**
	 * Differentiates between New and Edit modes
	 */
	const isNewArticle = useMemo(() => {
		return !formData?.id;
	}, [formData]);

	/**
	 * Whether to enable or not the moderation messages,
	 * top and bottom of the form - while in Create mode.
	 */
	const enableModerationMessage = useMemo(() => {
		const moderatedTypes = [NodeType.Kbentry];

		return isNewArticle && moderatedTypes.includes(formType);
	}, [isNewArticle, formType]);

	// Loads the Preview of the Cover image
	const articleCoverImagePreview = useMemo(() => {
		if (!formData?.coverImagePath && formData.coverImageType === CoverImageType.Default) return "";

		// When file has just been uploaded,
		// Demonstrates it as a file
		if (formData.coverImageType === CoverImageType.Upload && formData.coverImageFile !== null) {
			return formData.coverImageFile;
		}

		// When the cover image has been preloaded for the Form Edit mode
		// And path is there: returns it for using.
		if (
			formData.coverImageType === CoverImageType.Loaded &&
			formData.coverImagePath !== null &&
			formData.coverImagePath !== ""
		) {
			return formData.coverImagePath;
		}

		if (
			(previousImagePath.current !== formData.coverImagePath || coverImageRender === "") &&
			formData.coverImagePath !== ""
		) {
			previousImagePath.current = formData?.coverImagePath;
			ServiceHub.appFileAPI
				.start()
				.getCoverImageByURL(formData.coverImagePath)
				.then((result) => {
					if (!result) return;

					const imageUrl: string = URL.createObjectURL(result);
					setCoverImageRender(imageUrl);
				});
		}

		return coverImageRender;
	}, [
		previousImagePath,
		coverImageRender,
		formData?.coverImagePath,
		formData?.coverImageType,
		formData?.coverImageFile
	]);

	const enabledFields = useMemo<string[]>(() => {
		let _fieldIds = [];

		switch (formType) {
			case NodeType.Question: {
				_fieldIds = [
					NodeFormFieldIds.title,
					NodeFormFieldIds.body,
					NodeFormFieldIds.topics,
					NodeFormFieldIds.space,
					NodeFormFieldIds.selectCover,
					NodeFormFieldIds.selectedCoverView
				];
				break;
			}
			default:
				_fieldIds = Object.values(NodeFormFieldIds);
				break;
		}

		return _fieldIds;
	}, [formType]);

	/**
	 * Drives the user back to the root for accessing an invalid route,
	 * unauthorized.
	 */
	function redirectToRoot() {
		// When the owner is not the one loading the data,
		// Evades the user to the root page of the app
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.root());
	}

	/**
	 * Loads the data from the services.
	 * Also, will ensure the user has access to the data,
	 * prior to loading it.
	 */
	async function loadData(): Promise<void> {
		if (!articleFormService.current.getLoading() && !articleFormService.current.getLoaded()) {
			articleFormService.current.setLoading(true);

			// Pre-loads all data required for the dropdowns,
			// Selectors, an so on
			await articleDataAPI.current.getSpaces().then((result) => articleFormService.current.setSpacesList(result));
			await articleDataAPI.current.getMiscellaneous().then((result) => {
				if (result.length > 0) {
					articleFormService.current.setCoverImageTypesList(result);
				}
			});
			await nodeAPI.current.getNodesAllTopics().then((result) => {
				articleFormService.current.setTopicsList(result);
			});

			articleFormService.current.setLoading(false);
			articleFormService.current.setLoaded(true);
		}
	}

	/**
	 * Checkes whether the control is currently enabled to be displayed on the screen
	 * to end-user.
	 *
	 * @param fieldId The field ID to check enabled or not.
	 * @returns Whether the field should render or not.
	 */
	const isFieldEnabled = useCallback(
		(fieldId: string): boolean => {
			return enabledFields.includes(fieldId);
		},
		[enabledFields]
	);

	async function loadParentNode(nodeItem: IStrikeNodeEntity) {
		if (!nodeItem.ParentId || nodeItem.ParentId === null || isNaN(nodeItem.ParentId)) return;

		articleFormService.current.setParentArticlesList([
			{ Id: nodeItem.ParentId, Title: nodeItem.ParentTitle, Type: NodeType.Kbentry }
		]);
	}

	/**
	 * Sets the formData, by preparing the data from IStrikeNodeEntity data
	 * loading process.
	 *
	 * @param formData The server form data (response) to be set at the state layer.
	 */
	function setFormData(formData: IStrikeNodeEntity) {
		const coverImagePath =
			formData.Base64CoverImage !== null && formData.Base64CoverImage !== "" ? formData.Base64CoverImage : null;

		articleFormService.current.setData({
			// Prepares defaults to merge along
			...articleFormInitialState.data,
			id: formData.Id.toString(),
			title: formData.Title,
			body: formData.Body,
			authorUpn: formData.AuthEmail,
			type: undefined !== formData.Type && formData.Type !== "" ? NodeType[formData.Type] : "",
			topics: formData.TopicsName !== null && formData.TopicsName !== "" ? formData.TopicsName.split(",") : [],
			spaceId: formData.ContainerId,
			parentId: formData.ParentId ?? null,
			coverImageId: formData.CoverImageId !== null ? formData.CoverImageId : null,
			coverImagePath: coverImagePath,
			coverImageType: !coverImagePath ? CoverImageType.Default : CoverImageType.Loaded
		});
	}

	/**
	 * Checks whether the form loading has the same type of
	 * the Node data loading.
	 * If not matching, security and/or integrity measures
	 * must be taken.
	 *
	 * @param nodeType The node type coming from server, to test
	 * @returns boolean
	 */
	function matchingTypes(nodeType: string): boolean {
		const _nodeType = Object.values(NodeType).find((tp) => tp === nodeType);

		return _nodeType === formType;
	}

	// Pre-loads the data, in case params.id is specified
	async function loadNodeData() {
		if (params?.id !== formData.id) {
			if (
				!articleFormService.current.getQueryingNode() &&
				params?.id !== undefined &&
				params?.id !== null &&
				params?.id !== ""
			) {
				articleFormService.current.setQueryingNode(true);

				ServiceHub.nodeAPI
					.getById(parseInt(params.id))
					.then((result) => {
						// Checks whether the loaded data matches the context (article/question)
						if (!matchingTypes(result.Type)) {
							// When the owner is not the one loading the data,
							// Evades the user to the root page of the app
							redirectToRoot();
						}

						if (
							result.Type === NodeType.Kbentry &&
							result.ContainerId !== null &&
							result.ContainerId !== 0
						) {
							loadParentNode(result);
						}

						setFormData(result);
					})
					.catch((ex) => {
						// Handles an error case, data not found or service down
						ServiceHub.message.error(ex);
					})
					.finally(() => {
						articleFormService.current.setQueriedNode(true);
						articleFormService.current.setQueryingNode(false);
					});
			} else if (formData.id !== "") {
				articleFormService.current.resetData();
				articleFormService.current.setQueriedNode(false);
				articleFormService.current.setQueryingNode(false);
			}
		}
	}

	// Pre-loads general data to be used from the hook
	useEffect(() => {
		loadData();
	}, [loadData]);

	// Pre-loads general data to be used from the hook
	useEffect(() => {
		loadNodeData();
	}, [loadNodeData, params.id]);

	// Unmount clean-up hook
	useEffect(() => {
		articleFormService.current.resetData();

		return () => {
			articleFormService.current.reset();
		};
	}, []);

	return {
		loadData,
		formData,
		parentArticles,
		addedTopics: formData.addedTopics,
		topics,
		parentSpaces,
		spaces,
		coverImageTypes,
		parentArticleQuery,
		topicsText,
		articleCoverImagePreview,
		enabledFields,
		// Boolean states
		canAccessNodeType: nodeAdminAccessControl.canUserAccessNodeType(formType),
		canAddTopics,
		coverImageGalleryOpen,
		enableModerationMessage,
		isNewArticle,
		bodySourceMode,
		virtualHTML,
		hasError,
		submitting,
		loading,
		loaded,
		canSubmit,
		validation,
		// Querying states
		queryingSpaces,
		// Queried states
		// Behaviors
		getChildrenSpaces,
		setHasError,
		isFieldEnabled
		// setAddedTopics
	} as IUseNodeFormHook;
};
