/**
 * Fists instance of Definition Constants for the Project
 */

const Constants = {
	filterScroll: {
		offSetHeight: 76
	},
	zoom: {
		level: 200
	},
	buttonDashboardsTexts: {
		Dashboards: "Dashboards",
		Articles: "Articles",
		Questions: "Questions"
	},
	deleteConfirmationTexts: {
		DeleteText: "Delete",
		DeleteArticleTitle: "Delete Article",
		DeleteQuestionTitle: "Delete Question",
		DeleteSuccess: "Deleted Successfully",
		DeleteArticleSubText: "Are you sure you want to delete this article?",
		DeleteQuestionSubText: "Are you sure you want to delete this question?",
		DeleteGoToDashboard: "Go to Dashboard"
	},
	undeleteConfirmationTexts: {
		UndeleteText: "Undelete",
		UndeleteArticleTitle: "Undelete Article",
		UndeleteQuestionTitle: "Undelete Question",
		UndeleteSuccess: "Undeleted Successfully",
		UndeleteArticleSubText: "Are you sure you want to undelete this article?",
		UndeleteQuestionSubText: "Are you sure you want to undelete this question?",
		UndeleteGoToDashboard: "Go to Dashboard"
	},
	publishConfirmationTexts: {
		PublishText: "Publish",
		PublishArticleTitle: "Publish Article",
		PublishArticleSubText: "Are sure you want to publish this article?",
		ArticlePublished: "Article published successfully"
	},
	postedConfirmationTexts: {
		ArticlePosted: "Article posted successfully",
		ArticlePostedText: "The moderator will review and publish the Article."
	},
	stickConfirmationTexts: {
		stickToSpaceTitle: "Stick Node to Space",
		stickToSpaceSubTitle: "Are you sure you want to stick this node to the Space?",
		unstickFromSpaceTitle: "Unstick Node from Space",
		unstickFromSpaceSubTitle: "Are you sure you want to unstick this node from the Space?",
		stickToSiteTitle: "Stick Node to Website",
		stickToSiteSubTitle: "Are you sure you want to stick this node to Website?",
		unstickFromSiteTitle: "Unstick Node from Website",
		unstickFromSiteSubTitle: "Are you sure you want to unstick this node from Website?"
	},
	privateConfirmationTexts: {
		makeArticlePrivateTitle: "Make Article Private",
		makeArticlePrivateSubTitle: "Are you sure you want to set this Article as Private?",
		makeArticlePublicTitle: "Make Article Public",
		makeArticlePublicSubTitle: "Are you sure you want to set this Article as Public?",
		makeQuestionPrivateTitle: "Make Question Private",
		makeQuestionPrivateSubTitle: "Are you sure you want to set this Question as Private?",
		makeQuestionPublicTitle: "Make Question Public",
		makeQuestionPublicSubTitle: "Are you sure you want to set this Question as Public?"
	},
	navigationSideBar: {
		title: "Navigations"
	}
};

export default Constants;
