import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Divider, Segment } from "semantic-ui-react";
import { NodeActionTypes, NodeType, TitleType } from "../../contracts/models/strikeEnums";
import { useAuthUser } from "../../hooks/auth/useAuthUser";
import {
	activityUser,
	createAuthoriableUser,
	getQueryCreateAuthUser,
	getQueryUserActivityObject,
	renderNodePdf,
	validateEmail
} from "../../lib/strikeLibrary";
import StrikeNode from "../../models/StrikeNode";
import { setAllNodeComments, setNodeId, setNodeLikeByUsers, setNodeLikeCount } from "../../redux/reducers/comments";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import PageHeader from "../Header/page.header";
import { AuthorMention } from "../common/article";
import { NodeActivity, RichTextNode } from "../common/node";
import { ArticleImgShimmer, ArticleShimmer, NodeActivityShimmer, TopicsShimmer } from "../common/shimmer";
import { TreeViewNavigation } from "./articles.navigation";
import { ArticleError } from "./shared/article.error";
import { useSelector } from "react-redux";
import { commentsSelectors } from "../../redux/selectors/comments";
import { ServiceHub } from "../../service";
import { ApplicationRoutePaths } from "../router";
import { IStrikeNodeEntity } from "../../contracts/models";
import { Button } from "../common/button";
import { ConfirmationMessage } from "../common/message";
import { useNodeActionsSlice } from "../../hooks/node/useNodeActions";
import Constants from "../../constants/Constants";
import {
	setDeleteConfirmationModalButtonText,
	setDeleteConfirmationModalSubText,
	setDeleteConfirmationModalTitle,
	setIsActionConfirmed,
	setIsOpenDeleteConfirmation,
	setIsOpenPrivateConfirmation,
	setIsOpenStickConfirmation
} from "../../redux/reducers/nodes";
import { FollowType } from "../../enums";
import { FollowerSection } from "../common/follower";
import { CoverDisplayImage } from "../common/image";
import { useNodeOwner } from "../../hooks";
import { NodeActivityMainCommentForm } from "../common/node/activity/nodeActivity.main.comment.form";
import { setTagsIds, setTagsNames } from "../../redux/reducers/tags";
import { ShowEditTags } from "../common/tags/showEditTags";
import { CustomPanel } from "../common/panel/Panel";
import { NodeChangeSpaceForm } from "../common/node/space/nodeChangeSpace";
import { useRef } from "react";
import { nodesSelectors } from "../../redux/selectors/nodes";
import { Breadcrumbs } from "../common/breadcrumb/breadcrumb";
import { ShareContentPanel } from "../common/share";
import { NodeRevisions } from "../common/node/revisions/node.revisions";
import { NodeReportPanel } from "../common/node/report/node.report";

/**
 * Controls the props of Article Viewer component
 */
interface IShowArticleProps {}

/**
 * Article viewer component.
 *
 * @param props IShowArticleProps
 * @returns React.FunctionComponent<IShowArticleProps>
 */
export const ShowArticle: React.FunctionComponent<IShowArticleProps> = (props) => {
	const params = useParams();
	const dispatch = useDispatch();
	const reportService = ServiceHub.commentAPI.start();
	const { userPrincipalName, userDisplayName, userId } = useAuthUser();
	const [nodeDetails, setNodeDetails] = useState<IStrikeNodeEntity>(new StrikeNode(0, "", "", ""));
	const nodeId = useSelector(commentsSelectors.getNodeId);
	const [isLoadingNodes, setIsLoadingNodes] = useState(false);
	const [isLoadedNodes, setIsLoadedNodes] = useState(false);
	const ipAddress = "";
	const [hasArticleBeenFound, setHasArticleBeenFound] = useState(true);
	const NodeActions = useNodeActionsSlice();
	const isOpenDeleteConfirmation = NodeActions.isOpenDeleteConfirmation;
	const isOpenStickConfirmation = NodeActions.isOpenStickConfirmation;
	const isOpenPrivateConfirmation = NodeActions.isOpenPrivateConfirmation;
	const isActionConfirmed = NodeActions.isActionConfirmed;
	const confirmationTitle = NodeActions.confirmationModalTitle;
	const confirmationSubText = NodeActions.confirmationModalSubText;
	const isChangeSpacePanelOpen = NodeActions.isChangeSpacePanelOpen;
	const isRevisionsPanelOpen = NodeActions.isRevisionsPanelOpen;
	const isNodeOwner = useNodeOwner();
	const resolvedNodeOwner = isNodeOwner({ Id: nodeDetails.Id, AuthEmail: nodeDetails.AuthEmail });
	const openChangeSpacePanel = NodeActions.openChangeSpacePanel;
	const dismissChangeSpacePanel = NodeActions.dismissChangeSpacePanel;
	const openRevisionsPanel = NodeActions.openRevisionsPanel;
	const dismissRevisionsPanel = NodeActions.dismissRevisionsPanel;
	const isStickSpaceSticky = useSelector(nodesSelectors.getStickSpaceStatus);
	const isStickSiteSticky = useSelector(nodesSelectors.getStickSiteStatus);
	const isPrivateStatus = useSelector(nodesSelectors.getPrivateStatus);
	const isDeletedStatus = useSelector(nodesSelectors.getDeletedStatus);
	const [stickConfirmationTitle, setStickConfirmationTitle] = useState("");
	const [stickConfirmationSubTitle, setStickConfirmationSubTitle] = useState("");
	const [privateConfirmationTitle, setPrivateConfirmationTitle] = useState("");
	const [privateConfirmationSubTitle, setPrivateConfirmationSubTitle] = useState("");
	const favouriteAPI = useRef(ServiceHub.followAPI.start());
	const { commentId } = useParams<{ commentId: string}>();
	const [commentsLoaded, setCommentsLoaded] = useState(false);
	const [isOpenSendModerationConfirmation, setIsOpenSendModerationConfirmation] = useState<boolean>(false);

	// set treenav height
	const mainArticleColumnRef = useRef<HTMLDivElement>(null);
	const nodeActivityColumnRef = useRef<HTMLDivElement>(null);
	const headerColumnRef = useRef<HTMLDivElement>(null);
	const followComponentRef = useRef<HTMLDivElement>(null);
	const [sideColumnHeight, setSideColumnHeight] = useState<number>(0);
	const location = useLocation();
	const [isOpenReportCommentConfirmation, setIsOpenReportCommentConfirmation] = useState(false);

	useEffect(() => {
		if(commentId && commentsLoaded) {
			const commentElement = document.getElementById(commentId);
			if (commentElement) {
				commentElement.scrollIntoView({
					behavior: 'smooth',
					block: 'end'
				});
			}
		}
	}, [commentId, commentsLoaded]);

	/**
	 * Set height for Node right column based on the article content
	 * and apply scrollbar to tree nav items
	 */
	useEffect(() => {
		const setHeight = () => {
			if (
				mainArticleColumnRef.current &&
				nodeActivityColumnRef.current &&
				followComponentRef.current &&
				headerColumnRef.current
			) {
				const mainColumnHeight =
					mainArticleColumnRef.current.clientHeight + nodeActivityColumnRef.current.clientHeight;
				const totalArticleHeight = mainColumnHeight + headerColumnRef.current.clientHeight;
				const followComponentHeight = followComponentRef.current.clientHeight;
				setSideColumnHeight(totalArticleHeight - followComponentHeight);
			}
		};

		const timeoutId = setTimeout(() => {
			setHeight();
		}, 5000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [location]);

	useEffect(() => {
		async function onLoad() {
			if (params.id) {
				if (!isLoadingNodes && (!isLoadedNodes || params.id != nodeId)) {
					if (Number(params.id) > 0) {
						setIsLoadingNodes(true);
						await getNodeDetails();
						setIsLoadingNodes(false);
						setIsLoadedNodes(true);
						dispatch(setNodeId(params.id));
					}
				}
			}
			if (validateEmail(userPrincipalName) && nodeId) {
				let logActivity = getQueryUserActivityObject(
					NodeActionTypes.NodeView,
					ipAddress,
					nodeId,
					userPrincipalName
				);
				let authoriableUser = getQueryCreateAuthUser(userId, userDisplayName, userPrincipalName);
				activityUser(logActivity);
				createAuthoriableUser(authoriableUser);
			}
		}
		onLoad();
	}, [params.id, userPrincipalName, userDisplayName, isLoadingNodes, isLoadedNodes, userId, nodeId]);

	/**
	 * Function to get the node Details from server
	 */
	const getNodeDetails = useCallback(async () => {
		try {
			const res = await NodeAPIServiceInstance.getById(Number(params.id));
			if (res.Id) {
				setNodeDetails(res);
				dispatch(setNodeLikeCount(res.LikeCount));
				dispatch(setNodeLikeByUsers(res.LikeByUsers));
				getNodeComments();
				getNodeTopics();
				favouriteAPI.current.setIsFavourite(res.IsFavourite);
				ServiceHub.appManagementAPI.setNodeDetailAuthorEmail(res.AuthEmail);
				ServiceHub.appManagementAPI.setNodeStickSpaceStatus(res.IsSpaceSticky);
				ServiceHub.appManagementAPI.setNodeStickSiteStatus(res.IsSiteSticky);
				ServiceHub.appManagementAPI.setNodePrivateSiteStatus(res.IsPrivate);
				ServiceHub.appManagementAPI.setNodeDeletedStatus(res.IsDeleted);
			}
		} catch (error) {
			setHasArticleBeenFound(false);
			console.error("Error getting the details of the Article", error);
		}
	}, [params.id, NodeAPIServiceInstance]);

	/**
	 * Renders for display an RTE node
	 *
	 * @returns
	 */
	function basicEvent(): React.ReactNode {
		return nodeDetails.Title ? <RichTextNode>{nodeDetails.Body}</RichTextNode> : null;
	}

	/**
	 * Function to get node comments for a specific nodeId
	 * using the API Service call
	 * Response saved into global state
	 */
	const getNodeComments = async () => {
		let res = await NodeAPIServiceInstance.getNodeComments(Number(params.id));
		if (res) {
			dispatch(setAllNodeComments(res));
			setCommentsLoaded(true)
		}
	};

	/**
	 * Function to get node Topics for a specific nodeId
	 * using the API Service call
	 */
	const getNodeTopics = async () => {
		let res = await NodeAPIServiceInstance.getNodeTopics(Number(params.id));
		if (res) {
			dispatch(setTagsIds(res.TopicId.split(",")));
			dispatch(setTagsNames(res.TopicName.split(",")));
		}
	};

	/**
	 *
	 * @returns The img which will be displayed in the top of the treeView, if the Article has his own or should display Default
	 */
	function renderImage(): React.ReactNode {
		return <CoverDisplayImage node={nodeDetails} loading={isLoadingNodes} noShimmer />;
	}

	/**
	 * Redirecting user to edit form once called from node settings
	 */
	function onEdit() {
		const route = ApplicationRoutePaths.articleEdit(nodeId);
		ServiceHub.appManagementAPI.navigate(route);
	}

	/**
	 * Called from node settings Delete or Undelete
	 */
	function onDelete() {
		dispatch(setIsOpenDeleteConfirmation(true));
		dispatch(setIsActionConfirmed(false));
		if (!isDeletedStatus) {
			dispatch(setDeleteConfirmationModalTitle(Constants.deleteConfirmationTexts.DeleteArticleTitle));
			dispatch(setDeleteConfirmationModalSubText(Constants.deleteConfirmationTexts.DeleteArticleSubText));
			dispatch(setDeleteConfirmationModalButtonText(Constants.deleteConfirmationTexts.DeleteText));
			ServiceHub.appManagementAPI.setConfirmationCallback(onDeleteConfirmation);
		} else {
			dispatch(setDeleteConfirmationModalTitle(Constants.undeleteConfirmationTexts.UndeleteArticleTitle));
			dispatch(setDeleteConfirmationModalSubText(Constants.undeleteConfirmationTexts.UndeleteArticleSubText));
			dispatch(setDeleteConfirmationModalButtonText(Constants.undeleteConfirmationTexts.UndeleteText));
			ServiceHub.appManagementAPI.setConfirmationCallback(onUndeleteConfirmation);
		}
	}

	/**
	 * Passing object data to api to delete Article
	 */
	function onDeleteConfirmation() {
		NodeActions.onDeleteConfirmation(nodeId, NodeActionTypes.Delete, userPrincipalName, NodeType.Kbentry);
	}

	/**
	 * Passing object data to api to undelete Article
	 */
	function onUndeleteConfirmation() {
		NodeActions.onUndeleteConfirmation(nodeId, NodeActionTypes.UnDelete, userPrincipalName, NodeType.Kbentry);
	}

	function onPublishArticle() {
		dispatch(setIsActionConfirmed(false));
		dispatch(setIsOpenDeleteConfirmation(true));
		dispatch(setDeleteConfirmationModalTitle(Constants.publishConfirmationTexts.PublishArticleTitle));
		dispatch(setDeleteConfirmationModalSubText(Constants.publishConfirmationTexts.PublishArticleSubText));
		dispatch(setDeleteConfirmationModalButtonText(Constants.publishConfirmationTexts.PublishText));
		ServiceHub.appManagementAPI.setConfirmationCallback(onPublishArticleConfirmation);
	}

	function onPublishArticleConfirmation() {
		NodeActions.onPublishConfirmation(NodeActionTypes.Publish, ipAddress, nodeId, userPrincipalName);
	}

	function renderNodeActivityOrPublishButton() {
		if (nodeDetails.IsPublished) {
			if (nodeDetails.Id) {
				return (
					<div ref={nodeActivityColumnRef}>
						<NodeActivity nodeId={parseInt(params?.id) ?? nodeDetails.Id} type={NodeType.Kbentry} />
						<NodeActivityMainCommentForm nodeId={nodeDetails.Id} type={NodeType.Kbentry} />
					</div>
				);
			} else {
				return <NodeActivityShimmer />;
			}
		} else if (nodeDetails.Id) {
			return (
				<div className="strike-article-publish-button">
					<Button text={"Publish"} onClick={onPublishArticle} />
				</div>
			);
		} else {
			return <NodeActivityShimmer />;
		}
	}

	/**
	 * Getting a fresh update of node topics list
	 * after save edit.
	 */
	function onTopicSaveComplete() {
		getNodeTopics();
	}

	/**
	 * method to pass to header action to trigger
	 * and open Change Space Panel
	 */
	function onChangeSpace() {
		openChangeSpacePanel();
	}

	/**
	 * method to pass to header action to trigger
	 * and open Node Revisions Panel
	 */
	function onNodeRevision() {
		NodeActions.onGetNodeRevisions(nodeId);
		openRevisionsPanel();
	}

	/**
	 * method to call html to pdf library 
	 * and export node content to pdf
	 */
	function onExportToPdf() {
		renderNodePdf('Article', nodeId);
	}

	/**
	 * Add Stick Node to Space
	 */
	function stickNodeToSpace() {
		NodeActions.onAddStickNode(nodeId, nodeDetails.ContainerId.toString());
	}

	/**
	 * Remove Stick Node From Space
	 */
	function unstickNodeFromSpace() {
		NodeActions.onRemoveStickNode(nodeId, nodeDetails.ContainerId.toString());
	}
	/**
	 * call methods to stick article to space
	 */
	function onStickySpace() {
		dispatch(setIsOpenStickConfirmation(true));
		if (!isStickSpaceSticky) {
			setStickConfirmationTitle(Constants.stickConfirmationTexts.stickToSpaceTitle);
			setStickConfirmationSubTitle(Constants.stickConfirmationTexts.stickToSpaceSubTitle);
			ServiceHub.appManagementAPI.setConfirmationCallback(stickNodeToSpace);
		} else {
			setStickConfirmationTitle(Constants.stickConfirmationTexts.unstickFromSpaceTitle);
			setStickConfirmationSubTitle(Constants.stickConfirmationTexts.unstickFromSpaceSubTitle);
			ServiceHub.appManagementAPI.setConfirmationCallback(unstickNodeFromSpace);
		}
	}

	/**
	 * Add Stick Node to Site
	 */
	function stickNodeToSite() {
		NodeActions.onAddStickNode(nodeId, null);
	}

	/**
	 * Remove Stick Node From Site
	 */
	function unstickNodeFromSite() {
		NodeActions.onRemoveStickNode(nodeId, null);
	}
	/**
	 * call methods to stick article to entire website
	 */
	function onStickyWebsite() {
		dispatch(setIsOpenStickConfirmation(true));
		if (!isStickSiteSticky) {
			setStickConfirmationTitle(Constants.stickConfirmationTexts.stickToSiteTitle);
			setStickConfirmationSubTitle(Constants.stickConfirmationTexts.stickToSiteSubTitle);
			ServiceHub.appManagementAPI.setConfirmationCallback(stickNodeToSite);
		} else {
			setStickConfirmationTitle(Constants.stickConfirmationTexts.unstickFromSiteTitle);
			setStickConfirmationSubTitle(Constants.stickConfirmationTexts.unstickFromSiteSubTitle);
			ServiceHub.appManagementAPI.setConfirmationCallback(unstickNodeFromSite);
		}
	}

	/**
	 * Call API to set node as private
	 */
	function makeNodePrivate() {
		NodeActions.onMakeNodePrivate(nodeId, NodeActionTypes.MakePrivate, userPrincipalName, NodeType.Kbentry);
	}

	/**
	 * Call API to set node as public
	 */
	function makeNodePublic() {
		NodeActions.onMakeNodePublic(nodeId, NodeActionTypes.MakePublic, userPrincipalName, NodeType.Kbentry);
	}

	/**
	 * Method to switch between Private/Public api calls and content set to modal
	 */
	function onMakeNodePrivateToggle() {
		dispatch(setIsOpenPrivateConfirmation(true));
		if (!isPrivateStatus) {
			setPrivateConfirmationTitle(Constants.privateConfirmationTexts.makeArticlePrivateTitle);
			setPrivateConfirmationSubTitle(Constants.privateConfirmationTexts.makeArticlePrivateSubTitle);
			ServiceHub.appManagementAPI.setConfirmationCallback(makeNodePrivate);
		} else {
			setPrivateConfirmationTitle(Constants.privateConfirmationTexts.makeArticlePublicTitle);
			setPrivateConfirmationSubTitle(Constants.privateConfirmationTexts.makeArticlePublicSubTitle);
			ServiceHub.appManagementAPI.setConfirmationCallback(makeNodePublic);
		}
	}

	/**
	 * Handling Close action for Report Article Panel
	 */
	const onCloseReportCommentConfirmation = () => {
		setIsOpenReportCommentConfirmation(false);
	}

	/**
	 * Main action to open report Panel
	 */
	function onReportContent(){
		setIsOpenReportCommentConfirmation(true);
		ServiceHub.appManagementAPI.resetReportReasonValueId();
	}

	/**
	 * Main method to hit api to send article to moderation 
	 */
	function sendModerationNode() {
		const nodeUrl = window.location.toString();
		const reportQueryObject = reportService.getQueryObjectToReportComment(parseInt(nodeId), parseInt(nodeId),nodeUrl, userPrincipalName, NodeActionTypes.ModerationRequest);

		reportService.reportComment(reportQueryObject).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			setIsOpenSendModerationConfirmation(false);
		})
	}

	/**
	 * Handling Send Node To Moderation 
	 */
	function onSendNodeToModeration(){
		setIsOpenSendModerationConfirmation(true);
		ServiceHub.appManagementAPI.setConfirmationCallback(sendModerationNode);
	}

	/**
	 * Closing Send To Modration confirmation modal
	 */
	const onCloseSendModerationConfirmation = () => {
		setIsOpenSendModerationConfirmation(false);
	}

	return (
		<React.Fragment>
			<Segment basic className="strike-node-wrapper">
				<div className="article-view">
					{hasArticleBeenFound ? (
						<div className="row article-layout">
							<div className="row col-md-12 mb-3">
								<Breadcrumbs nodeId={nodeDetails.Id} />
							</div>
							<div className="col-md-8 article-layout-main" tabIndex={-1}>
								<div className="article-header-section" ref={headerColumnRef}>
									<PageHeader
										Title={nodeDetails.Title}
										Type={TitleType.Kbentry}
										onEdit={onEdit}
										onDelete={onDelete}
										onChangeSpace={onChangeSpace}
										onNodeRevision={onNodeRevision}
										onStickySpace={onStickySpace}
										onStickyWebsite={onStickyWebsite}
										onMakePrivate={onMakeNodePrivateToggle}
										onExportToPdf={onExportToPdf}
										onReportContent={onReportContent}
										onSendNodeToModeration={onSendNodeToModeration}
										showActions={isLoadedNodes}
										isOwner={resolvedNodeOwner}
										enableShareContent
									/>
									{nodeDetails.Id ? (
										<>
											<ShowEditTags
												nodeId={params?.id}
												type={NodeType.Kbentry}
												authorUpn={nodeDetails.AuthEmail}
												onComplete={onTopicSaveComplete}
											/>
											<Divider />
										</>
									) : (
										<TopicsShimmer />
									)}
								</div>
								<AuthorMention
									authEmail={nodeDetails.AuthEmail}
									authName={nodeDetails.AuthName}
									createdOn={nodeDetails.CreatedOn}
								/>
								<div
									className="strike-article"
									data-markup-language="froala"
									ref={mainArticleColumnRef}
									tabIndex={-1}
								>
									{nodeDetails.Id ? basicEvent() : <ArticleShimmer />}
								</div>
								
								{renderNodeActivityOrPublishButton()}
							</div>

							<div className="col-md-4 article-layout-side">
								{nodeDetails.Id ? renderImage() : <ArticleImgShimmer />}
								<div ref={followComponentRef}>
									<FollowerSection
										title="Follow this Article"
										nodeId={params?.id}
										type={FollowType.Node}
										customNodeTypeName="article"
									/>
								</div>

								<h4 tabIndex={0}>{Constants.navigationSideBar.title}</h4>
								<Divider />
								<div style={{
										maxHeight: sideColumnHeight > 0 ? `${sideColumnHeight}px` : "unset"
									}}
								>
									<TreeViewNavigation sideColumnHeight={sideColumnHeight} />
								</div>
							</div>
						</div>
					) : (
						<>
							<ArticleError />
						</>
					)}
				</div>
				<ConfirmationMessage
					isOpen={isOpenDeleteConfirmation}
					isActionConfirmed={isActionConfirmed}
					onClose={NodeActions.onClose}
					onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
					title={confirmationTitle}
					text={confirmationSubText}
				/>
				<ConfirmationMessage
					isOpen={isOpenStickConfirmation}
					isActionConfirmed={isActionConfirmed}
					onClose={NodeActions.onCloseStickyConfirmation}
					onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
					title={stickConfirmationTitle}
					text={stickConfirmationSubTitle}
				/>
				<ConfirmationMessage
					isOpen={isOpenPrivateConfirmation}
					onClose={NodeActions.onClosePrivateConfirmation}
					onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
					title={privateConfirmationTitle}
					text={privateConfirmationSubTitle}
				/>
				<ConfirmationMessage
					isOpen={isOpenSendModerationConfirmation}
					onClose={onCloseSendModerationConfirmation}
					onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
					title="Send to Moderation"
					text="Are you sure you want to send this Article to moderation?"
					confirmationText="Yes"
					dismissText="No"
				/>
				<CustomPanel
					isOpen={isChangeSpacePanelOpen}
					onDismiss={dismissChangeSpacePanel}
					headerText="Change Space"
				>
					<NodeChangeSpaceForm nodeTitle={nodeDetails.Title} type={NodeType.Kbentry} />
				</CustomPanel>
				<CustomPanel
					isOpen={isOpenReportCommentConfirmation}
					onDismiss={onCloseReportCommentConfirmation}
					headerText="Report Article"
					>
					<NodeReportPanel NodeId={parseInt(nodeId)} setOpenReportPanel={setIsOpenReportCommentConfirmation} />
				</CustomPanel>
				<ShareContentPanel nodeType={NodeType.Kbentry} formTitle={nodeDetails.Title} />
					<div className="export-to-pdf-template" id="export-to-pdf-template">
						<h2>{nodeDetails.Title}</h2>
						<AuthorMention
							authEmail={nodeDetails.AuthEmail}
							authName={nodeDetails.AuthName}
							createdOn={nodeDetails.CreatedOn}
						/>
						<div
							className="strike-article"
							data-markup-language="froala"
							ref={mainArticleColumnRef}
							tabIndex={-1}
						>
							{nodeDetails.Id ? basicEvent() : <ArticleShimmer />}
						</div>
					</div>
				<CustomPanel
					isOpen={isRevisionsPanelOpen}
					onDismiss={dismissRevisionsPanel}
					headerText="Revisions"
				>
					<NodeRevisions />
				</CustomPanel>
			</Segment>
		</React.Fragment>
	);
};
