import followPaginationConstants from "../../../components/common/user/listBox/constants";
import { IFollowSlice } from "../../../contracts/slices/follow/IFollowSlice";

/**
 * The Initial State of Follow slice.
 */
export const followInitialState: IFollowSlice = {
	data: {
		followers: []
	},
	ui: {
		followersToShow: followPaginationConstants.paginationOffset,
		querying: false,
		queried: false,
		isFavourite: false
	}
};
