import { setPhotoByUserUpn, setQueried, setQuerying } from "../../redux/reducers/nodes";
import store from "../../redux/store";
import BaseHttpAPI from "../base/BaseHttpAPI";
import { MsalService } from "../../auth";
import { base64Encode } from "../../lib/strikeLibrary";
import { nodesSelectors } from "../../redux/selectors/nodes";
import { ServiceHub } from "../base/ServiceHub";

let currentUserNew: any;

/**
 * Class for handling requests to gather User Profile
 * info via backend services.
 */
export class UserProfileAPIService extends BaseHttpAPI {
	constructor() {
		super("userprofiles");
	}

	/**
	 * Gets an User Profile picture based on its e-mail (upn).
	 *
	 * @param userUpn The user e-mail (upn) to query a profile picture from.
	 * @param ac [optional] The abort controller
	 * @returns The profile picture already acquired.
	 */
	async getProfilePhoto(userUpn: string, ac?: undefined | AbortController) {
		const b64Upn = btoa(userUpn);

		this.setPhotoQuerying(userUpn, true);

		return ServiceHub.graphAPI
			.start()
			.getProfilePhoto(userUpn)
			.then(async (data) => {
				this.setUserByUpn(b64Upn, data);

				return data;
			})
			.catch((ex) => {
				// TODO: Handle image loading errors
			})
			.finally(() => {
				this.setPhotoQuerying(userUpn, false);
				this.setPhotoQueried(userUpn, true);
			});
	}

	async setPhotoQuerying(userUpn: string, querying: boolean) {
		const b64Upn = btoa(userUpn);

		store.dispatch(setQuerying({ userUpn: b64Upn, querying }));
	}

	async setPhotoQueried(userUpn: string, queried: boolean) {
		const b64Upn = btoa(userUpn);

		store.dispatch(setQueried({ userUpn: b64Upn, queried }));
	}

	/**
	 * Gets an User Profile information from the graph API.
	 */

	async InitializeCurrentUserProfile() {
		//Getting logged user details from the Graph API
		const currentUser = await MsalService.GetCurrentUser();

		if (!currentUser && !currentUser.userPrincipalName) {
			Promise.reject("Cannot retrieve current logged in user profile using MsalService");
			return null;
		}
		currentUserNew = currentUser;

		// return userProfile.UserPrincipalName ? userProfile.UserPrincipalName : null;
		return currentUserNew.userPrincipalName ? currentUserNew.userPrincipalName : null;
	}

	/**
	 * Sets user photo based on userPrincipalName on the state layer
	 * @param upn
	 * @param data
	 */
	async setUserByUpn(upn: string, data: string | { Data: string }) {
		store.dispatch(
			setPhotoByUserUpn({
				userUpn: upn,
				photoUrl: typeof data === "string" ? data : !data.Data || data.Data === "" ? false : data.Data
			})
		);
	}

	/**
	 * Gets an User Profile information.
	 *
	 * @returns The user profile full name.
	 */

	async GetCurrentUserDisplayName() {
		return currentUserNew && currentUserNew.givenName + " " + currentUserNew.surname;
	}

	/**
	 * Gets an User Profile information.
	 *
	 * @returns The profile principal name.
	 */

	async GetCurrentUserUpn() {
		return currentUserNew && currentUserNew.userPrincipalName;
	}

	/**
	 * Gets an User Profile information.
	 *
	 * @returns The profile email.
	 */
	async GetCurrentUserEmail() {
		return currentUserNew && currentUserNew.mail;
	}

	/**
	 * Gets an user UPN information.
	 *
	 * @returns The profile email.
	 */
	async GetCurrentUserId() {
		return currentUserNew && currentUserNew.id;
	}

	/**
	 * Gets an User Profile information.
	 *
	 * @returns The entire use profile information object.
	 */

	async GetCurrentUserProfile() {
		return currentUserNew;
	}

	/**
	 * Checks whether the logged user can access the
	 * Article and Questions component.
	 *
	 * @returns True if access is authorized, false if not.
	 */
	async canAccessArticleQuestion(): Promise<boolean> {
		const base64Upn = base64Encode(await this.GetCurrentUserUpn());

		return await this.get(`CheckComponentAuthorization/${base64Upn}`)
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			});
	}

	async loadProfilePictures(
		userPrincipalName: string,
		abortController?: undefined | AbortController
	): Promise<NodeJS.Timeout> {
		const state = store.getState();
		const b64upn = userPrincipalName !== null ? btoa(userPrincipalName) : "";
		const userProfilePhoto = nodesSelectors.getUserPhoto(state, b64upn);
		const userProfilePhotoQuerying = nodesSelectors.getQuerying(state, b64upn);
		const userProfilePhotoQueried = nodesSelectors.getQueried(state, b64upn);

		return setTimeout(async () => {
			try {
				if (b64upn && !userProfilePhoto && !userProfilePhotoQueried && !userProfilePhotoQuerying) {
					await this.getProfilePhoto(userPrincipalName, abortController);
				}
			} catch (ex: any) {
				if (ex?.name === "AbortError") {
					abortController?.abort();
				}
			}
		}, 500);
	}

	/**
	 * Dedicated method to load Profile Pictures specific on Follow component
	 * This version doesnt require timeOut delay
	 * @param userPrincipalName
	 * @param abortController
	 */
	async loadFollowersProfilePictures(
		userPrincipalName: string,
		abortController?: undefined | AbortController
	): Promise<void> {
		const state = store.getState();
		const b64upn = userPrincipalName !== null ? btoa(userPrincipalName) : "";
		const userProfilePhoto = nodesSelectors.getUserPhoto(state, b64upn);
		const userProfilePhotoQuerying = nodesSelectors.getQuerying(state, b64upn);
		const userProfilePhotoQueried = nodesSelectors.getQueried(state, b64upn);

		try {
			if (b64upn && !userProfilePhoto && !userProfilePhotoQueried && !userProfilePhotoQuerying) {
				await this.getProfilePhoto(userPrincipalName, abortController);
			}
		} catch (ex: any) {
			if (ex?.name === "AbortError") {
				abortController?.abort();
			}
		}
	}
}
