import { createSelector } from "@reduxjs/toolkit";
import { IArticleFormData } from "../../contracts/article/articleForm";
import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { ICoverImageInfo, IListControl, INodeQueryResponse, ISpaceItem } from "../../contracts";
import { DropdownItemProps } from "semantic-ui-react";
import { ITreeItem } from "@coherence-design-system/tree-view";
import React from "react";
import { ServiceHub } from "../../service";

/**
 * Article Form data access selectors.
 */
export const articleFormSelectors = {
	getData: (state): IArticleFormData => {
		return state.articleForm.data;
	},
	getParentArticlesList: (state): INodeQueryResponse[] => {
		return state.articleForm.list.parentArticles;
	},
	getSpacesList: (state): ISpaceItem[] => {
		return state.articleForm.list.spaces;
	},
	getSpaceById: createSelector(
		(state) => state,
		(_, itemId: number | string) => itemId,
		(state, itemId): ISpaceItem => {
			return state.articleForm.list.spaces.find((item) => item.Id === itemId);
		}
	),
	getParentSpacesList: createSelector(
		(state) => state,
		(state): ISpaceItem[] => {
			return state.articleForm.list.spaces.filter((space) => space.ParentId === null || space.ParentId === "");
		}
	),
	getCoverImageTypesList: (state): [] => {
		return state.articleForm.list.coverImageTypes;
	},
	getCoverImagesListControl: (state): IListControl<ICoverImageInfo> => {
		return state.articleForm.list.coverImages;
	},
	getCoverImagesListPage: (state): number => {
		return state.articleForm.list.coverImages.currentPage;
	},
	getCoverImagesListPageLength: (state): number => {
		return state.articleForm.list.coverImages.pageLength;
	},
	getCoverImagesListTotal: (state): number => {
		return state.articleForm.list.coverImages.totalCount;
	},
	getCoverImagesList: (state): ICoverImageInfo[] => {
		return state.articleForm.list.coverImages.items;
	},
	getTopicsList: (state): string[] => {
		return state.articleForm.list.topics;
	},
	getParentArticleComboBoxOptions: createSelector(
		(state) => state,
		(state): IComboBoxOption[] => {
			return state.articleForm.list.parentArticles.map((item) => ({ value: item.Id, text: item.Title }));
		}
	),
	getExpandedSpacesById: createSelector(
		(state) => state,
		(_, spaceId: number | string) => spaceId,
		(state, spaceId: number | string): boolean => {
			return state.articleForm.expandedSpaces.includes(spaceId);
		}
	),
	getSpacesDropdownOptions: createSelector(
		(state) => state,
		(state): IDropdownOption[] => {
			return state.articleForm.list.spaces.map((item) => ({ value: item.Id, text: item.Title }));
		}
	),
	getChildrenSpacesDropdownOptions: createSelector(
		(state) => state,
		(_, parentId: string | number) => parentId,
		(state, parentId: string | number): ITreeItem[] => {
			return state.articleForm.list.spaces
				.filter((space) => space.ParentId === parentId)
				.map((item) => articleFormSelectors.makeSpacesDropdownOption(state, item));
		}
	),
	getParentSpacesDropdownOptions: createSelector(
		(state) => state,
		(state): ITreeItem[] => {
			return state.articleForm.list.spaces
				.filter((space) => space.ParentId === null || space.ParentId === "")
				.map((item) => articleFormSelectors.makeSpacesDropdownOption(state, item));
		}
	),
	makeSpacesDropdownOption: createSelector(
		(state) => state,
		(_, item: ISpaceItem) => item,
		(state, item: ISpaceItem): ITreeItem => {
			const selectedSpaceId = state.articleForm.data.spaceId;

			return {
				id: item.Id,
				title: item.Title,
				isDisabled: item.IsParent,
				children: articleFormSelectors.getChildrenSpacesDropdownOptions(state, item.Id),
				isExpanded: articleFormSelectors.getExpandedSpacesById(state, item.Id),
				isSelected: selectedSpaceId === item.Id,
				onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, treeItem: ITreeItem) => {
					// Updates the state layer by adding the selected item as current selected space
					const articleFormSvc = ServiceHub.articleForm.start();
					const previousData = articleFormSvc.getData();
					let newShape: IArticleFormData;

					if (!previousData.spaceId || previousData.spaceId === "" || previousData.spaceId !== treeItem.id) {
						newShape = { ...previousData, spaceId: treeItem.id };
						articleFormSvc.setData(newShape);
						articleFormSvc.setExpandedSpaceById(parseInt(treeItem.id), true);
						return;
					}

					// In this case, it is unselecting the space
					newShape = { ...previousData, spaceId: "" };
					articleFormSvc.setData(newShape);
					articleFormSvc.setExpandedSpaceById(parseInt(treeItem.id), false);
				}
			} as ITreeItem;
		}
	),
	getCoverImageTypesDropdownOptions: createSelector(
		(state) => state,
		(state): IDropdownOption[] => {
			return state.articleForm.list.coverImageTypes.map((item) => ({ value: item.Id, text: item.Name }));
		}
	),
	getTopicsComboBoxOptions: createSelector(
		(state) => state,
		(_, externalItems: string[]) => externalItems,
		(state, externalItems: string[]): DropdownItemProps[] => {
			const resultantCollection: string[] = [
				...externalItems,
				...state.articleForm.list.topics.filter((topic: string) => !externalItems.includes(topic))
			];

			return resultantCollection.map(
				(item): DropdownItemProps => ({
					key: item,
					value: item,
					text: item
					// selected: state.articleForm.data.topics?.includes(item)
				})
			);
		}
	),
	getQueryingParentArticles: (state): boolean => state.articleForm.querying.parentArticles ?? false,
	getQueryingSpaces: (state): boolean => state.articleForm.querying.spaces ?? false,
	getQueryingCoverImageTypes: (state): boolean => state.articleForm.querying.coverImageTypes ?? false,
	getQueryingCoverImages: (state): boolean => state.articleForm.querying.coverImages ?? false,
	getQueryingTopics: (state): boolean => state.articleForm.querying.topics ?? false,
	getQueryingNode: (state): boolean => state.articleForm.querying.node ?? false,
	getQueriedParentArticles: (state): boolean => state.articleForm.queried.parentArticles ?? false,
	getQueriedSpaces: (state): boolean => state.articleForm.queried.spaces ?? false,
	getQueriedCoverImageTypes: (state): boolean => state.articleForm.queried.coverImageTypes ?? false,
	getQueriedCoverImages: (state): boolean => state.articleForm.queried.coverImages ?? false,
	getQueriedTopics: (state): boolean => state.articleForm.queried.topics ?? false,
	getQueriedNode: (state): boolean => state.articleForm.queried.node ?? false,
	getLoading: (state): boolean => state.articleForm.loading ?? false,
	getLoaded: (state): boolean => state.articleForm.loaded ?? false,
	getParentArticleQuery: (state): string => state.articleForm.parentArticleQuery ?? "",
	getCoverImageGalleryOpen: (state): boolean => state.articleForm.coverImageGalleryOpen ?? false,
	getTopicsText: (state): string => state.articleForm.topicsText ?? "",
	getBodySourceMode: (state): boolean => state.articleForm.bodySourceMode,
	getSubmitting: (state): boolean => state.articleForm.submitting,
	getGallery: (state): { imagePath: { [key: string]: string } } => state.articleForm.gallery,
	getGalleryImagePathById: createSelector(
		(state) => state,
		(_, imageId: string) => imageId,
		(state, imageId): string => state.articleForm.gallery.imagePath[imageId] ?? ""
	)
};
