import React from "react";
import { FieldClear } from "./field.clear";
import { FieldRequiredMark } from "./field.requiredMark";

/**
 * The FormField properties to control its input.
 */
export interface IFormFieldProps {
	children: React.ReactElement | React.ReactElement[] | JSX.Element | JSX.Element[];
	id: number | string;
	label?: undefined | string;
	className?: undefined | string;
	required?: undefined | boolean;
	value?: undefined | string | number | string[] | [] | boolean;
	onClear: (event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => void;
}

/**
 * Standard form field component. Should be used within a form element,
 * by passing properties to it.
 *
 * @param props Properties to enable the FormField component.
 * @returns React.FC<IFormFieldProps>
 */
export const FormField: React.FC<IFormFieldProps> = (props) => {
	const conditions = {
		className: `form-field ${props?.className ?? ""}`
	};
	const fieldId = props.id.toString();

	return (
		<div className={conditions.className} id={`form-field-${fieldId}`}>
			{props?.label && props?.label !== "" ? (
				<label htmlFor={fieldId} aria-label={`Field: ${props.label}`}>
					{props.label}&nbsp;{!props.required ? "" : <FieldRequiredMark />}&nbsp;
					{typeof props?.onClear === "function" ? (
						<FieldClear 
						onClear={props?.onClear ?? undefined} 
						value={props?.value} 
						/>
					) : null}
				</label>
			) : null}
			{props.children}
		</div>
	);
};
