import React, { useEffect } from "react";
import { Divider, DropdownItemProps, DropdownOnSearchChangeData, DropdownProps, Segment } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { useState } from "react";
import { IEventRegisteredUsers, IMSGraphUserBasic } from "../../../contracts/models";
import { ServiceHub } from "../../../service";
import { useRef } from "react";
import { useMemo } from "react";
import { getQueryUserToEventCheckin, shapeUserEventRegistration, utcToLocalDateString } from "../../../lib/strikeLibrary";
import { Dropdown, TreeViewDropdown } from "../../common/dropdown";
import { useEventForm } from "../../../hooks";
import { IconButton } from "../../common/button";
import { FormActions, FormContainer, FormField } from "../../common/form";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { useSuperAdminAccessControl } from "../../../hooks/auth/useSuperAdminAccessControl";
import ErrorPage from "../../ui/ErrorPage";

/**
 * Controls the props of Event Check-In component
 */
interface IEventCheckInDetailsProps {}

/**
 * Event Check-In component to allow user to search
 * through the registered users and confirm check-in.
 *
 * @param props IShowEventCheckInProps
 * @returns React.FunctionComponent<IShowEventProps>
 */
export const EventCheckInDetails: React.FunctionComponent<IEventCheckInDetailsProps> = (props) => {
	const eventManageAPI = useRef(ServiceHub.eventManageAPI.start());
	const eventFormService = useRef(ServiceHub.eventForm.start());
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { eventSelectList, eventSelectedItem, eventSelectedId } = useEventForm();
	const searchTimeout = useRef<NodeJS.Timeout>(null);
	const graphAPI = useRef(ServiceHub.graphAPI.start());
	const [attendees, setAttendees] = useState<IEventRegisteredUsers[]>([]);
	const [filterText, setFilterText] = useState<string>("");
	const [filterOption, setFilterOption] = useState<string>("");
	const CountsOptionStatsRef = useRef(null);
	const [filterButtonsAriaLabel, setFilterButtonsAriaLabel] = useState('')
	const [userToRegister, setUserToRegister] = useState<null | string>('');
	const [userNameToRegister, setUserNameToRegister] = useState<null | string>('');
	const [allowedUsersItems, setAllowedUsersItems] = useState<null | DropdownItemProps[]>([]);
	const [userList, setUserList] = useState<DropdownItemProps[]>([]);
	const [currentSearch, setCurrentSearch] = useState<string>("");
	const resolvedOptions: DropdownItemProps[] = [...allowedUsersItems, ...userList];
	const [announcement, setAnnouncement] = useState("");
	const eventsAPI = useRef(ServiceHub.eventsAPI.start());
	const eventsManageAPI = useRef(ServiceHub.eventManageAPI.start());
	const [eventDetails, setEventDetails] = useState<any>([]);
	const [eventMixedType, setEventMixedType] = useState<string | undefined>("");
	const id = "";
	const [userResetKey, setUserResetKey] = useState<number>(0);
	const RegisterUserSelectorRef = useRef(null);
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
	const [hasVolunteerAccess, setHasVolunteerAccess] = useState(false);
	const [volunteerAccessRequested, setVolunteerAccessRequested] = useState(false);
	const hasAccessToEventCheckin = isSuperAdmin && requested || volunteerAccessRequested && hasVolunteerAccess;

	const columns: IColumn[] = [
		{ key: "column1", name: "Name", fieldName: "Name", minWidth: 150, maxWidth: 150, isResizable: true },
		{ key: "column2", name: "Email", fieldName: "Email", minWidth: 170, maxWidth: 170, isResizable: true },
		{ key: "column3", name: "RSVP Type", fieldName: "RSVPType", minWidth: 70, maxWidth: 70, isResizable: true },
		{
			key: "column4",
			name: "Reg On",
			fieldName: "",
			minWidth: 100,
			maxWidth: 100,
			isResizable: true,
			onRender: (item) => {
				return <span>{utcToLocalDateString(item.RegistrationDate)}</span>;
			}
		},
		{
			key: "column5",
			name: "Updated On",
			fieldName: "",
			minWidth: 100,
			maxWidth: 100,
			isResizable: true,
			onRender: (item) => {
				return <span>{utcToLocalDateString(item.RegistrationUpdateDate)}</span>;
			}
		},
		{
			key: "column6",
			name: "Confirmed?",
			fieldName: "",
			minWidth: 80,
			maxWidth: 80,
			isResizable: true,
			onRender: (item) => {
				if (item.Confirmed) {
					return <span className="event-registration-label-confirmed">Yes</span>;
				} else {
					return <span className="event-registration-label-waiting">No</span>;
				}
			}
		},
		{
			key: "column7",
			name: "Checked-In?",
			fieldName: "",
			minWidth: 80,
			maxWidth: 80,
			isResizable: true,
			onRender: (item) => {
				if (item.CheckedIn) {
					return <span className="event-registration-label-checkedin">Yes</span>;
				} else {
					return <span className="event-registration-label-pending">No</span>;
				}
			}
		},
		{
			key: "column8",
			name: "",
			fieldName: "",
			minWidth: 80,
			maxWidth: 80,
			isResizable: true,
			onRender: (item) => {
				if (item.CheckedIn) {
					return (
						<div className="event-checkin-list-actions">
							<p>Checked-In</p>
						</div>
					);
				} else {
					return (
						<div className="event-checkin-list-actions">
							<IconButton
								iconName="SkypeCircleCheck"
								ariaLabel="Check-In User"
								onClick={() => handleUserCheckIn(item.Id, item.Email)}
								title="Check-In User"
								text="Check-In"
								className="event-registration-checkin-button"
								disabled={item.CheckedIn}
							/>
						</div>
					);
				}
			}
		}
	];

	/**
	 * Handling user search via input
	 * @param event
	 * @param newValue
	 */
	const handleSearch = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
		setFilterText(newValue);
	};

	/**
	 * Updating list of Attendees after search
	 */
	const filteredRegisteredUserList = useMemo(() => {
		let filtered = attendees.filter(
			(user) =>
				user.Name.toLowerCase().includes(filterText?.toLowerCase() || "") ||
				user.Email.toLowerCase().includes(filterText?.toLowerCase() || "")
		);

		if (filterOption === "confirmed") {
			filtered = filtered.filter((user) => user.Confirmed);
		}
		if (filterOption === "waiting") {
			filtered = filtered.filter((user) => !user.Confirmed);
		}
		if (filterOption === "checkedin") {
			filtered = filtered.filter((user) => user.CheckedIn);
		}
		if (filterOption === "virtual") {
			filtered = filtered.filter((user) => user.RSVPType === "Virtual");
		}
		if (filterOption === "inperson") {
			filtered = filtered.filter((user) => user.RSVPType === "In Person");
		}
		return filtered;
	}, [attendees, filterText, filterOption]);


	/**
	 * Validation if user already registered to event
	 */
	const userAlreadyRegistered = useMemo(() => {
		return filteredRegisteredUserList.some((user) => user.Email === userToRegister)
		
	}, [filteredRegisteredUserList, userToRegister]);

	/**
	 * Get global total number of confirmed users
	 * looking into the first user if any available
	 */
	const totalConfirmed = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].ConfirmedRegistrationCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Get global total number of checked-in users
	 * looking into the first user if any available
	 */
	const totalCheckedIn = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].CheckedInCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Get global total number of waiting users
	 * based on the first user if any available
	 */
	const totalWaiting = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].WaitingRegistrationCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Get global total number of In Person assisting users
	 * based on the first user if any available
	 */
	const totalInPerson = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].InPersonCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Get global total number of In Person assisting users
	 * based on the first user if any available
	 */
	const totalVirtual = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].VirtualCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Checking In user from the Register users list via 'Check-In' button
	 * @param userId
	 * @param userEmail
	 */
	const handleUserCheckIn = (userId: number, userEmail: string) => {
		if (userId && userEmail && eventSelectedId) {
			let userToCheckInQuery = getQueryUserToEventCheckin(userId, userEmail);

			eventManageAPI.current.setEventAttendeeCheckin(userToCheckInQuery).then((result) => {
				if (result) {
					eventManageAPI.current.getEventAttendeesList(eventSelectedId).then((refreshedAttendees) => {
						if (refreshedAttendees) {
							setAttendees(refreshedAttendees);
						}
					});
				}
			});
		}
	};

	useEffect(() => {
		document.title = 'Registrations - Strike Community';

		eventFormService.current.setIsEventListLoading(true);
		eventFormService.current.setIsEventListLoaded(false);
		async function loadEventsList() {
			eventManageAPI.current
				.getEvents()
				.then(async (items) => {
					eventFormService.current.setEventsList(items);
				})
				.finally(() => {
					eventFormService.current.setIsEventListLoading(false);
					eventFormService.current.setIsEventListLoaded(true);
				});
		}
		loadEventsList();
	}, []);

	useEffect(() => {
		if(eventSelectedId) {
		eventsAPI.current
		.getEvent(eventSelectedId)
		.then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			onClearField();
			setEventDetails(result);
		}) }
		
	}, [eventSelectedId]);

	function checkEventAccess(eventId: number) {
		eventsAPI.current
			.getVolunteerAccess(eventId)
			.then((result) => {
				if (result !== true) {
					setHasVolunteerAccess(false);
					return;
				}
				setHasVolunteerAccess(true);
				return;
			})
			.finally(() => {
				setVolunteerAccessRequested(true);
			});
	};

	/**
	 * Get Registered users for that specific Event Selected
	 * @param item
	 */
	function handleEventSelect(item) {
		if (item) {
			eventManageAPI.current.getEventAttendeesList(item.id).then((result) => {
				if (result) {
					setAttendees(result);
				}
			});

			checkEventAccess(item.id)
		}
	}

	/**
	 * handle filter by option from value passed from item click test
	 * @param option
	 */
	const handleFilterByOption = (event: React.MouseEvent<HTMLButtonElement>, option: string) => {
		setFilterButtonsAriaLabel('')
		if (event.currentTarget.classList.contains('selected')) {
			setFilterOption('');
			setFilterButtonsAriaLabel('Un Selected')
		} else {
			setFilterOption(option);
			setFilterButtonsAriaLabel('Selected')
		}
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.keyCode == 9 && event.target.tagName === 'BUTTON') {
				setFilterButtonsAriaLabel('')
			} 
		};
		window.addEventListener('keydown', handleKeyPress);
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, []);


	/**
	 * Handles the On Change event from the User to Share With.
	 *
	 * @param event The event happening
	 * @param data The instant DropdownProps state
	 */
	const onChangeUser = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
			const dropdownItem = data.options.find((option) => data.value === option.value);		
			setUserToRegister(data.value.toString());
			setUserNameToRegister(dropdownItem.title);
	};

	/**
	 * Handles the event of changing the Search text,
	 * When querying users from MS Graph.
	 *
	 * @param event The event happening.
	 * @param data The Search Change event state.
	 */
	const onSearchChange = (
		event: React.SyntheticEvent<HTMLElement, Event>,
		data: DropdownOnSearchChangeData
	): void => {
		const search = data.searchQuery;
		setCurrentSearch(search);
	};

	/**
	 * When Searching the users is required,
	 * This method handles the processing.
	 * And check if there are results
	 */
	const onSearch = async () => {
		setIsLoading(true);
		try {
			const searchResults = await graphAPI.current.searchUsers(currentSearch);

			onConvertGraphUsersToDropdownOptions(searchResults);

			if (!searchResults || searchResults.length === 0) {
				setAnnouncement("No results found.");
			} else {
				setAnnouncement("");
			}
		} catch (error) {
			console.error("Error al buscar usuarios:", error);
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * Prepares the MS Graph User data to be bound to the
	 * DropdownOptions.
	 *
	 * @param users The user collection to process.
	 * @returns The converted DropdownOptions.
	 */
	const onConvertGraphUsersToDropdownOptions = (users: IMSGraphUserBasic[]) => {
		if (undefined === users || users === null || !Array.isArray(users)) return;
		const dropdownOptions = users.map((item): DropdownItemProps => {
			const userNameUpn = `${item.displayName} <${item.userPrincipalName}>`;

			return {
				key: item.userPrincipalName,
				title: item.displayName,
				text: userNameUpn,
				"aria-label": `Add user: ${item.displayName}`,
				value: item.userPrincipalName
			} as DropdownItemProps;
		});

		setUserList(dropdownOptions);
	};

	useEffect(() => {
		async function delayedSearch() {
			// Forcing a clean-up before a new timeout, since
			// It generates debounce effect
			if (searchTimeout.current !== null) clearTimeout(searchTimeout.current);

			searchTimeout.current = setTimeout(() => {
				onSearch();
			}, 500);
		}

		// Pre-processes the query, if specified
		delayedSearch();
	}, [currentSearch]);


	const registerAndCheckInUser = (checkin: boolean) => {
			let registrationData = shapeUserEventRegistration(
				id,
				eventDetails.Id,
				userToRegister,
				userNameToRegister,
				'attend',
				eventDetails.Type !== 'Mixed' ? eventDetails.Type : eventMixedType,
				checkin ? true : false
			);
			eventsManageAPI.current.userEventRegistrationAndCheckIn(registrationData).then((result: any) => {
				if (result instanceof Error) {
					ServiceHub.message.error(result.message);
					throw result;
				}
				onClearField();
				eventManageAPI.current.getEventAttendeesList(eventSelectedId).then((refreshedAttendees) => {
					if (refreshedAttendees) {
						setAttendees(refreshedAttendees);
					}
				});

				if (!result) {
					// Redirect the user to the event dashboard
					// show registration error
					return;
				}
				// show registration successfully
			});
	};

	// this extra option its only nneded for Mixed type of event
	const options_eventMixedType: IChoiceGroupOption[] = [
		{ key: "virtual", text: "Virtual" },
		{ key: "in_person", text: "In Person" }
	];

	const onChangeEventMixedType = React.useCallback(
		(ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
			setEventMixedType(option.key)
		},
		[eventMixedType]
	);

	/**
	 * Clears User search form and type
	 */
	const onClearField = (): void => {
		setUserResetKey(userResetKey + 1);
		setUserToRegister("");
		setEventMixedType("");
	};

	const authorizedContent = () => {
		return (
				<>
					<Divider />
					{userAlreadyRegistered ? <div className="alert alert-warning">This user its already registered on this Event.</div> : null}
					<div className="event-checkin">
						{eventSelectedItem ? (
							<>
								<SearchBox
									className="event-checkin-search-input"
									placeholder="Search Person by name or email"
									onChange={handleSearch}
									autoComplete="off"
								/>
								<div className="event-checkin-list-container">
									<DetailsList
										items={filteredRegisteredUserList}
										columns={columns}
										setKey="set"
										layoutMode={DetailsListLayoutMode.justified}
										selectionPreservedOnEmptyClick={false}
										selectionMode={SelectionMode.none}
										ariaLabelForSelectionColumn="Toggle selection"
										checkButtonAriaLabel="select row"
										className="event-checkin-list-container-list"
										focusZoneProps={{
											allowTabKey: true
										}}
									/>
								</div>
							</>
						) : (
							<p tabIndex={0} aria-label="No Records Found. Please select the Event.">
								No Records Found. Please select the Event.
							</p>
						)}
					</div>
					{eventSelectedItem ?
					<div className="register-checkin-user-input">
						<div className="register-checkin-user-container">
							<div className="register-checkin-user-container-column selector" ref={RegisterUserSelectorRef}>
								<FormField id="AllowUsers" className="register-checkin-user-input-field" onClear={onClearField} value={userToRegister}>
									<Dropdown
										key={userResetKey}
										fluid
										search
										selection
										multiSelect={false}
										id="registerUser"
										selectedValue={userToRegister}
										options={resolvedOptions}
										onChange={onChangeUser}
										onSearchChange={onSearchChange}
										ariaLabel="Register User"
										placeholder="Select User to Register and Check-In"
										renderLabel={(item) => {
											return item.title;
										}}
										closeOnEscape
										closeOnBlur
										useEnhancements
										allowAdditions={false}
									/>
								</FormField>
							</div>
							{eventDetails && eventDetails.Type === 'Mixed' ?
							<div className="register-checkin-user-container-column">
								<ChoiceGroup
									id="RSVP"
									role="radiogroup"
									selectedKey={eventMixedType}
									options={options_eventMixedType}
									onChange={onChangeEventMixedType}
									ariaLabelledBy={"Event Mixed Type"}
									disabled={userAlreadyRegistered || !userToRegister}
								/>
							</div> : null}
							<div className="register-checkin-user-container-column buttons">
								<FormActions
									actions={[
										{
											onClick: () => registerAndCheckInUser(true),
											text: "Register and Check-In",
											disabled: userAlreadyRegistered || !userToRegister

										},
										{
											onClick: () => registerAndCheckInUser(false),
											text: "Register",
											disabled: userAlreadyRegistered || !userToRegister
										}
									]}
								/>
							</div>
						</div>
					</div> : null}
				</>
		)
	}

	return (
		<React.Fragment>
			<Segment basic className="strike-node-wrapper event-checkin-page-wrapper">
				<div className="event-checkin-header-container">
					<div className="event-checkin-header-title">
						<div className="event-checkin-header-icon">
							<Icon
								name="calendar alternate outline"
								size="big"
								tabIndex={0}
								aria-label="Event Icon"
								role="img"
							/>
						</div>
						<div className="event-checkin-header-select">
							<TreeViewDropdown
								id="EventSelect"
								options={eventSelectList}
								onChange={handleEventSelect}
								placeholder={"Select Event Name"}
								selectedItem={eventSelectedItem}
							/>
						</div>
					</div>
					<div className="event-checkin-header-filters">
					{hasAccessToEventCheckin ?
						<div className="event-checkin-counts-container" ref={CountsOptionStatsRef}>
							{eventSelectedItem ? (
								<>
								<button
								tabIndex={0}
								className={`event-checkin-counts-actions ${
									filterOption === "confirmed" ? "selected" : ""
								}`}
									aria-label={`${filterButtonsAriaLabel} Confirm Registration Counts. Total ${totalConfirmed}`}
									onClick={(event) => handleFilterByOption(event, "confirmed")}
								>
									Confirm: <strong>{totalConfirmed}</strong>
								</button>
								<button
								tabIndex={0}
									className={`event-checkin-counts-actions ${
										filterOption === "waiting" ? "selected" : ""
									}`}
									aria-label={`${filterButtonsAriaLabel} Waiting Registration Counts. Total ${totalWaiting}`}
									onClick={(event) => handleFilterByOption(event, "waiting")}
								>
									Waiting: <strong>{totalWaiting}</strong>
								</button>
								<button
									className={`event-checkin-counts-actions ${
										filterOption === "checkedin" ? "selected" : ""
									}`}
									aria-label={`${filterButtonsAriaLabel} Checked-In Counts. Total ${totalCheckedIn}`}
									onClick={(event) => handleFilterByOption(event, "checkedin")}
								>
									Checked-In: <strong>{totalCheckedIn}</strong>
								</button>
								<button
									className={`event-checkin-counts-actions ${
										filterOption === "inperson" ? "selected" : ""
									}`}
									aria-label={`${filterButtonsAriaLabel} In Person Counts. Total ${totalInPerson}`}
									onClick={(event) => handleFilterByOption(event, "inperson")}
								>
									In Person: <strong>{totalInPerson}</strong>
								</button>
								<button
									className={`event-checkin-counts-actions ${
										filterOption === "virtual" ? "selected" : ""
									}`}
									aria-label={`${filterButtonsAriaLabel} Virtual Counts. Total ${totalVirtual}`}
									onClick={(event) => handleFilterByOption(event, "virtual")}
								>
									Virtual: <strong>{totalVirtual}</strong>
								</button>
								</>
								) : null}
							</div> : null }
						</div>
					</div>	
				{ hasAccessToEventCheckin ? authorizedContent() : volunteerAccessRequested ?
				<ErrorPage
					header={"We're Sorry but you don't have access to this page"}
					isHtml={true}
					message={`
					Please reach to Strike Online Team
					&nbsp;<a href="mailto:strikeonlineteam@microsoft.com">
						<b>strikeonlineteam</b>
					</a>
				`}
				/> : 
				<div className="event-checkin-default-message">
					<p tabIndex={0} aria-label="No Records Found. Please select the Event.">
						No Records Found. Please select the Event.
					</p>
				</div>
			}

			</Segment>
		</React.Fragment>
	);
};