import { MsalService, graphConfig, loginRequest } from "../auth";
import { IMSGraphUserBasic } from "../contracts/models";

/**
 * Mini-API for accessing MS Graph information straightforward.
 */
export class GraphAPIService {
	/**
	 * Gets a User Photo from the MSAL using direct
	 * approach, instead of passing through BE services.
	 *
	 * @param userUpn the User Principal Name
	 * @returns
	 */
	async getProfilePhoto(userUpn: string): Promise<string> {
		const instance = MsalService.Get();

		return new Promise((resolve, reject) => {
			const endpointUrl: string = graphConfig.graphUsersEndpoint + `/${userUpn}/photo/$value`;

			instance
				.acquireTokenSilent({
					scopes: loginRequest.scopes
				})
				.then((response) => {
					fetch(endpointUrl, {
						headers: {
							authorization: `Bearer ${response.accessToken}`
						}
					})
						.then((response) => (response.ok ? response.blob() : response.json()))
						.then((data) => {
							if (data.error) {
								reject(data);
								return;
							}

							resolve(URL.createObjectURL(data));
						})
						.catch((e) => {
							reject(new Error(e));
						});
				});
		});
	}

	/**
	 * Gets a list of users from the MS Graph.
	 * It uses displayName and userPrincipalName props
	 * during searching for User possibilities.
	 *
	 * @param search The text to search users based on it.
	 * @param top [Optional] The top list of users, by default, 15.
	 *
	 * @returns List of found users as IMSGraphUserBasic[]
	 */
	async searchUsers(search: undefined | string, top: undefined | number = 15): Promise<IMSGraphUserBasic[]> {
		if (search === "" || !search) {
			return;
		}

		const instance = MsalService.Get();
		const selectParams = "$select=displayName,userPrincipalName";
		const searchParams =
			search !== undefined && search !== ""
				? `$search=("displayName:${search}" OR "userPrincipalName:${search}")&`
				: ``;
		const filterParams = `$filter=endswith(userPrincipalName, '@microsoft.com')`;
		const countParams = "$count=true";
		const topParams = `$top=${top}`;
		const orderByParams = "$orderby=displayName";

		return new Promise((resolve, reject) => {
			instance
				.acquireTokenSilent({
					scopes: loginRequest.scopes
				})
				.then((response) => {
					fetch(
						graphConfig.graphUsersEndpoint +
							`?${searchParams}${selectParams}&${filterParams}&${countParams}&${topParams}&${orderByParams}`,
						{
							headers: {
								Authorization: `Bearer ${response.accessToken}`,
								ConsistencyLevel: "eventual"
							}
						}
					)
						.then((response) => (response.ok ? response.json() : response.body))
						.then((data) => {
							if (data.error) {
								reject(data);
								return;
							}

							resolve(data.value);
						})
						.catch((e) => {
							reject(new Error(e));
						});
				});
		});
	}
}
