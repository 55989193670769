import StrikeCommunityApi from "../api/strikeCommunityApi";
import { IFollowRelation, ISpaceFormResponse } from "../contracts";
import {
	DeleteActionModel,
	EventAuthAccessModel,
	EventFormInfoModel,
	IEventFormInfo,
	INodeShareRequest,
	IStrikeNodeBreadcrumb,
	IStrikeNodeEntity,
	IStrikeNodeSticky,
	IStrikeNodes,
	IStrikeNodesAnswer,
	IStrikeNodesComments,
	IStrikeNodesTopics,
	IStrikeTopicNode,
	IUserAcceptAnswer,
	IUserActivityModel,
	IUserAuthoriablesModel,
	QueryNodeModel,
	QueryTopicModel,
	StickyNodeRequest,
	TABLE_PAGE_SIZE,
	UpdateNodeStatusActionModel,
	UserAcceptAnswerModel,
	UserActivityModel,
	UserAuthoriablesModel
} from "../contracts/models";
import { FilterActionTypes, NodeType, UserDashboardsFiltersType } from "../contracts/models/strikeEnums";
import { FollowType } from "../enums";
import { base64Encode } from "../lib/strikeLibrary";
import { setIsOpenShareContent, setIsSubmittingShareContent } from "../redux/reducers/nodes";
import BaseHttpAPI from "./base/BaseHttpAPI";
import { ServiceHub } from "./base/ServiceHub";
import store from "../redux/store";

export class NodeAPIService extends BaseHttpAPI {
	private static _instance: NodeAPIService;

	private constructor() {
		super("node");
	}

	static get Instance() {
		return this._instance || (this._instance = new this());
	}

	async getById(id: number): Promise<IStrikeNodeEntity> {
		const response = await StrikeCommunityApi.getNode(id);
		// returning the product returned by the API
		return response;
	}

	/**
	 *
	 * @param queryNodeModel
	 * @returns response
	 */
	async getAll(queryNodeModel: QueryNodeModel): Promise<IStrikeNodes> {
		const response = await StrikeCommunityApi.getAllNodes(queryNodeModel);
		return response;
	}

	/**
	 *
	 * @returns response
	 */
	async getNodesAllTopics(): Promise<string[]> {
		const response = await StrikeCommunityApi.getNodesAllTopics();
		return response;
	}

	/**
	 * @param coverImageThumbnailPath
	 * Get Base 64 format Node Cover Image Thumbnail
	 * @returns response
	 */
	async getNodeCoverImageThumbnail(coverImageThumbnailPath: string): Promise<string> {
		const response = await StrikeCommunityApi.getNodeCoverImageThumbnail(coverImageThumbnailPath);
		return response;
	}

	/**
	 * @param attachmentImageThumbnailPath
	 * Get Base 64 format Node Comment Image Attachment
	 * @returns response
	 */
	async getNodeAttachmentImageThumbnail(attachmentImageThumbnailPath: string): Promise<string> {
		const response = await StrikeCommunityApi.getNodeAttachmentImageThumbnail(attachmentImageThumbnailPath);
		return response;
	}

	/**
	 * @param queryUserModel
	 * create Auth Users
	 */
	async createAuthoriablesUser(queryUserModel: UserAuthoriablesModel): Promise<IUserAuthoriablesModel> {
		const response = await StrikeCommunityApi.createAuthoritablesUser(queryUserModel);
		return response;
	}

	/**
	 * @param queryActivityModel
	 * user activity workflow in a node
	 */
	async logUserActivity(queryActivityModel: UserActivityModel): Promise<IUserActivityModel> {
		const response = await StrikeCommunityApi.logUserActivityWorkflow(queryActivityModel);
		return response;
	}

	/**
	 * @param nodeId
	 * get answers from a specific question node
	 */
	async getQuestionAllAnswers(id: number): Promise<IStrikeNodesAnswer[]> {
		const response = await StrikeCommunityApi.getQuestionAllAnswers(id);
		return response;
	}

	/**
	 * Get node comments for specific node id.
	 * @param id
	 * @returns
	 */
	async getNodeComments(id: number): Promise<IStrikeNodesComments[]> {
		const response = await StrikeCommunityApi.getNodeComments(id);
		return response;
	}

	/**
	 * @param
	 * get user event authorization based on token
	 */
	async getUserEventAuth(
		queryEventAuthAccess: EventAuthAccessModel,
		userEmail: string,
		eventId: string
	): Promise<boolean> {
		const response = await StrikeCommunityApi.getUserEventAuth(queryEventAuthAccess, userEmail, eventId);
		return response;
	}

	/**
	 *
	 * @param queryEventFormInfo
	 * @returns boolean
	 */
	async sendEventFormRegistration(queryEventFormInfo: EventFormInfoModel): Promise<IEventFormInfo> {
		const response = await StrikeCommunityApi.sendEventFormRegistration(queryEventFormInfo);
		return response;
	}

	/**
	 * check if user event already registered to an event
	 * @param queryEventFormInfo
	 * @returns boolean
	 */
	async checkEventFormRegistration(queryEventFormInfo: EventFormInfoModel): Promise<IEventFormInfo> {
		const response = await StrikeCommunityApi.checkEventFormRegistration(queryEventFormInfo);
		return response;
	}

	/**
	 * @returns Topic Object to extract the array of spaces which we have in the Strike Community
	 */
	async queryTopics(queryTopicModel: QueryTopicModel): Promise<IStrikeTopicNode> {
		const response = await StrikeCommunityApi.queryTopics(queryTopicModel);
		return response;
	}

	/**
	 * Get node topics for specific node id.
	 * @param id
	 * @returns
	 */
	async getNodeTopics(id: number): Promise<IStrikeNodesTopics> {
		const response = await StrikeCommunityApi.getNodeTopics(id);
		return response;
	}

	/**
	 * @param queryAcceptAnswer
	 * accept answer in a question node.
	 */
	async logUserAcceptAnswer(queryAcceptAnswer: UserAcceptAnswerModel): Promise<IUserAcceptAnswer> {
		const response = await StrikeCommunityApi.logUserAcceptAnswer(queryAcceptAnswer);
		return response;
	}

	/**
	 * Queries for the followers of a given Node Type by the Node ID,
	 * be it Topic, Space, or Node itself.
	 *
	 * @param nodeId The node ID to query followers for.
	 * @param followType The Node type itself
	 * @returns Collection of FollowRelations
	 */
	async getFollowers(
		nodeId: number | string,
		followType: FollowType,
		ac: AbortController
	): Promise<IFollowRelation[]> {
		const svc = ServiceHub.followAPI.start();

		return this.get<IFollowRelation[]>(`followers/${followType}/${nodeId}`, ac)
			.then((result) => {
				svc.setFollowers(result);
				return result;
			})
			.catch((ex) => {
				// TODO: Handle the load error scenario
				return [];
			})
			.finally(() => {
				svc.setQueried(true);
				svc.setQuerying(false);
			});
	}

	/**
	 * Follows/unfollows a given Node ID,
	 * based on the type of the Node itself
	 * and whether the action is Follow or not.
	 *
	 * @param nodeId The node to be followed
	 * @param followType The Type of the Node being followed (Topic, Space, Node)
	 * @param isFollow Whether this is a follow or unfollow
	 */
	async follow(nodeId: number | string, followType: FollowType, isFollow: boolean): Promise<any> {
		const userUpn = await ServiceHub.userProfileAPI.start().GetCurrentUserUpn();
		const userName = await ServiceHub.userProfileAPI.start().GetCurrentUserDisplayName();

		return this.post(`follow/${nodeId}`, {
			actionType: followType,
			userUpn,
			isFollow
		}).then(async (result) => {
			if (isFollow) {
				// TODO: Dispatch thunked changes
				ServiceHub.followAPI.start().addFollower({
					followType,
					NodeId: parseInt(nodeId.toString()),
					UserUPN: userUpn,
					UserName: userName
				});
			} else {
				// TODO: Dispatch thunked changes
				ServiceHub.followAPI.start().removeFollower(userUpn);
			}

			return result;
		});
	}


	async favourite(nodeId: number | string, isFavourite: boolean): Promise<any> {
		const userUpn = await ServiceHub.userProfileAPI.start().GetCurrentUserUpn();

		return this.post(`favourite/`, {
			nodeId: nodeId,
			isFavourite: isFavourite,
			favouritedBy: userUpn,
		}).then(async (result) => {
			return result;
		});
	}

	/**
	 * Delete Node Action
	 * @param nodeId
	 * @param actionType
	 * @returns
	 */
	async deleteNode(nodeId: string, actionType: DeleteActionModel): Promise<boolean> {
		const response = await ServiceHub.nodeAdminAPI.start().deleteNode(nodeId, actionType);
		return response;
	}

	/**
	 * Update Node Status
	 * Make Private/ Public
	 * @param nodeId
	 * @param actionType
	 * @returns
	 */
	async UpdateNodeStatus(actionType: UpdateNodeStatusActionModel): Promise<boolean> {
		const response = await ServiceHub.nodeAdminAPI.start().UpdateNodeStatus(actionType);
		return response;
	}

	/**
	 *
	 * @param actionType
	 * @param userUpn
	 * @returns
	 */
	getQueryDeleteNodeObject(actionType: string, userUpn: string, nodeId: string): DeleteActionModel {
		return {
			actionType: actionType,
			userUpn: userUpn,
			nodeId: nodeId
		} as DeleteActionModel;
	}

	/**
	 *
	 * @param actionType
	 * @param userUpn
	 * @param nodeId
	 * @returns
	 */
	getQueryUpdateStatusNodeObject(actionType: string, userUpn: string, nodeId: string): UpdateNodeStatusActionModel {
		return {
			actionType: actionType,
			userUpn: userUpn,
			nodeId: nodeId
		} as UpdateNodeStatusActionModel;
	}

	/**
	 *
	 * @param actionType
	 * @param ipAddress
	 * @param nodeId
	 * @param emailId
	 * @returns
	 */
	getQueryPublishNodeObject(
		actionType: string,
		ipAddress: string,
		nodeId: string,
		emailID: string
	): UserActivityModel {
		return {
			actionType: actionType,
			ipAddress: ipAddress,
			nodeId: nodeId,
			emailID: emailID
		} as UserActivityModel;
	}

	/**
	 *
	 * @param nodeId
	 * @param spaceId
	 * @returns
	 */
	getQueryStickNodeObject(nodeId: string, spaceId?: string): StickyNodeRequest {
		return {
			nodeId: nodeId,
			spaceId: spaceId
		} as StickyNodeRequest;
	}
	/**
	 *
	 * @param node_Id
	 * @param queryPageIndex
	 * @param querySearchTitle
	 * @param querySearchTopics
	 * @param querySearchUrlTopics
	 * @param queryNodeType
	 * @returns
	 */
	getQueryNodeObject(
		node_Id: number,
		queryPageIndex: number,
		querySearchTitle?: string | undefined,
		querySearchTopics?: string | undefined,
		querySearchUrlTopics?: string | undefined,
		queryNodeType?: NodeType | undefined,
		querySortType?: FilterActionTypes | undefined,
		queryFilterType?: UserDashboardsFiltersType | undefined,
	): QueryNodeModel {
		const isTopics = location?.pathname?.includes("topics") ?? false;
		const resolvedNodeId = isTopics || node_Id === 0 || node_Id === null || isNaN(node_Id) ? 0 : node_Id;
		const resolvedTopicId = !isTopics || node_Id === 0 ? 0 : node_Id;

		return {
			NodeId: resolvedNodeId,
			TopicId: resolvedTopicId,
			PageIndex: queryPageIndex,
			PageLimit: TABLE_PAGE_SIZE,
			SearchTitle: querySearchTitle,
			SearchTopics: querySearchTopics,
			SearchUrlTopics: querySearchUrlTopics,
			Type: queryNodeType,
			SortBy: querySortType,
			FilterBy: queryFilterType
		} as QueryNodeModel;
	}

	/**
	 *
	 * @param node_Id
	 * @param queryPageIndex
	 * @param pageLimit
	 * @returns
	 */
	getQueryFavoritesNodeObject(
		node_Id: number,
		pageLimit: number,
		queryPageIndex: number,

	): QueryNodeModel {
		const isTopics = location?.pathname?.includes("topics") ?? false;
		const resolvedNodeId = isTopics || node_Id === 0 || node_Id === null || isNaN(node_Id) ? 0 : node_Id;


		return {
			NodeId: resolvedNodeId,
			PageIndex: queryPageIndex,
			PageLimit: pageLimit,
		} as QueryNodeModel;
	}

	/**
	 * Prepares the body img tags of a given Node.
	 * Should receive a callback for further processing.
	 *
	 * @param htmlData The HTML data to pre-process
	 * @param onComplete The completion callback, receives the prepared HTML content.
	 * @returns String
	 */
	prepareBodyImages(htmlData: string, onComplete: (processedHtml: string) => void): void {
		const parser = new DOMParser();
		const commentHtmlText = parser.parseFromString(htmlData, "text/html");
		const imgElements = commentHtmlText.getElementsByTagName("img");
		if (imgElements) {
			for (let i = 0; i < imgElements.length; i++) {
				const imageSource = imgElements[i].getAttribute("src");

				if (
					imageSource &&
					!imageSource.includes("data:application/octet-stream;base64") &&
					!imageSource.includes("data:image/")
				) {
					/**
					 * Removing default storage folder paths
					 * and getting only filename.
					 */
					ServiceHub.appFileAPI
						.start()
						.getAttachmentFileByURL(imageSource.replace("storage/attachments/", ""))
						.then((data: void | Blob) => {
							if (data) {
								const reader = new FileReader();
								reader.readAsDataURL(data);

								reader.onloadend = function () {
									const base64data = reader.result;
									imgElements[i].setAttribute("src", base64data.toString());
									if (typeof onComplete === "function")
										onComplete(commentHtmlText.documentElement.outerHTML);
								};
							}
						});
				}
			}
		}
	}

	/**
	 * Get Stycky Nodes for website or space
	 * @param spaceId?
	 * @returns list of sticky nodes
	 */
	async getStickyNodes(spaceId?: string): Promise<IStrikeNodeSticky[]> {
		let url = `sticky`;

		if (spaceId !== undefined && spaceId !== null) {
			url += `/${spaceId}`;
		}

		return this.get<IStrikeNodeSticky[]>(url);
	}

	/**
	 * Get the Breadcrum Route for articles and questions
	 * * @param nodeId
	 * @returns list of nodes which constitute the route
	 */
	async getBreadcrumbRoute(nodeId: string): Promise<IStrikeNodeBreadcrumb[]> {
		const url = `breadcrumbs/${nodeId}`;
		return this.get<IStrikeNodeBreadcrumb[]>(url);
	}

	/**
	 * Redux: Sets the current state of the Share Content panel,
	 * whereas true is Visible.
	 *
	 * @param open Whether it is open or closed.
	 */
	setShareContentOpen(open: boolean): void {
		store.dispatch(setIsOpenShareContent(open));
	}

	/**
	 * Redux: Sets the current Submitting state of the Share Content panel,
	 * whereas true is to enable button loaders.
	 *
	 * @param open Whether it is submitting or not.
	 */
	setShareContentSubmitting(submitting: boolean): void {
		store.dispatch(setIsSubmittingShareContent(submitting));
	}

	/**
	 * Shares Node content using a centralized approach,
	 * meanwhile all params are required. Use simplified methods
	 * for easier calls.
	 *
	 * @param nodeType The Node type being shared.
	 * @param recipientUserUPN The Principal Name of a user receiving the shared Node.
	 * @param nodeId Node shared Id.
	 * @param actionType The action type for a Share Operation.
	 * @returns The result flag for whether the operation succeeded or failed.
	 */
	async shareContent(
		nodeId: number,
		recipientUserUpn: string,
		recipientUserAlias: string,
		recipientUserRealName: string
	): Promise<boolean> {
		const url: string = "share";
		const base64EncodedStrings = {
			recipientUserUpn: base64Encode(recipientUserUpn),
			recipientUserAlias: base64Encode(recipientUserAlias),
			recipientUserRealName: base64Encode(recipientUserRealName)
		};

		return this.post<INodeShareRequest, boolean>(url, {
			nodeId,
			...base64EncodedStrings
		});
	}

	/**
	 * Get Space data details
	 * @param spaceId
	 * @returns
	 */
	async getSpace(spaceId: string): Promise<ISpaceFormResponse> {
		return await this.get(`getSpace/${spaceId}`)
			.then((result) => result as ISpaceFormResponse)
			.catch((error) => error);
	}
}

export const NodeAPIServiceInstance = NodeAPIService.Instance;
