import React from "react";
import footerLogo from "../../logo-microsoft.png";

/**
 * Application Footer
 * @returns
 */
const Footer = (): React.ReactElement => {
	return (
		<section className="footer-container" aria-label="Strike Footer">
			<div className="container d-flex">
				<div className="important-links">
					<ul>
						<li aria-label="Strike">
							STRIKE
						</li>
						<li>
							<a href="https://strikecommunity.azurewebsites.net/spaces/38/index.html">Events</a>
						</li>
						<li>
							<a href="https://strikecommunity.azurewebsites.net/spaces/36/index.html">Courses</a>
						</li>
					</ul>

					<ul>
						<li aria-label="Contact">
							Contact
						</li>
						<li>
							<a href="https://aka.ms/reportitnow">Report it Now</a>
						</li>
						<li>
							<a href="mailto:strikeonline@microsoft.com">Site Support</a>
						</li>
					</ul>
				</div>

				<div className="microsoft-logo">
					<a href="#">
						<img className="img-fluid" alt="Microsoft Logo" src={footerLogo} />
					</a>
					<p>
						© {new Date().getFullYear()} Microsoft |{" "}
						<a href="https://go.microsoft.com/fwlink/?LinkId=518021" className="mspvc" target="_blank">
							Privacy
						</a>
						<br />
						All Rights Reserved.
					</p>
				</div>
			</div>
		</section>
	);
};

export default Footer;
