import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";
import { ITreeItem, TreeView } from "@coherence-design-system/tree-view";
/**
 * Tree item definition
 */
export interface TreeItem extends ITreeItem {}

/**
 * Controls the input props of Strike's Dropdown.
 */
export interface ITreeViewDropdownProps {
	options: TreeItem[];
	onItemToggled?: (item: ITreeItem, isExpanded: boolean) => Promise<void> | void;
	ariaLabel?: undefined | string;
	ariaRequired?: undefined | boolean;
	title?: undefined | string;
	alt?: undefined | string;
	selectedItem?: undefined | { id?: undefined | string; text?: undefined | string };
	placeholder?: undefined | string;
	open?: undefined | boolean;
	setOpen?: undefined | ((open: boolean) => void);
	onClear?: undefined | (() => void);
	id?: undefined | string;
	disabled?: undefined | boolean;
	required?: undefined | boolean;
	inline?: undefined | boolean;
	search?: undefined | boolean;
	error?: undefined | boolean;
	noCloseOnSelect?: undefined | boolean;
	onChange?: undefined | ((clickedItem: ITreeItem) => void);
}

/**
 * Renders a Strike-standard Dropdown component.
 * Implements a Fluent UI 8 Dropdown within.
 *
 * @param props IDropdownProps
 * @returns React.FC<IDropdownProps>
 */
export const TreeViewDropdown: React.FC<ITreeViewDropdownProps> = (props) => {
	const divRef = useRef(null);
	const [listOpen, setListOpen] = useState(props?.open ?? false);
	const item = useMemo(
		() => (!props?.selectedItem?.id || props.selectedItem.id === "" ? null : props.selectedItem),
		[props?.selectedItem]
	);
	const isOpen = useMemo(() => {
		return undefined !== props?.open ? props.open : listOpen;
	}, [props?.open, listOpen]);

	const toggleOpen = useCallback(() => {
		if (props.disabled) return false;

		// Toggles the list to open/closed
		if (undefined !== props.open && typeof props?.setOpen === "function") {
			props.setOpen(!props?.open);
		} else {
			setListOpen(!listOpen);
		}
	}, [props.disabled, props.open, props.setOpen, listOpen]);

	/**
	 * Generates a function-wrapper to automatically close the select on click
	 */
	const createOnItemClick = useCallback(
		(item: TreeItem) => (ev: React.MouseEvent<HTMLElement, MouseEvent>, clickedItem: ITreeItem) => {
			const fnCopy = item.onClick;
			hideTooltip();

			fnCopy(ev, clickedItem);

			if (props?.noCloseOnSelect) return;

			if (typeof props?.onChange === "function") props?.onChange(clickedItem);

			toggleOpen();
		},
		[props?.onChange, props?.noCloseOnSelect, toggleOpen]
	);

	/**
	 * Maps an item, and its children to a protected
	 * clicking function which wraps each item's call with a component-controlled
	 * item clicking function.
	 */
	const mapItemClick = useCallback(
		(item: TreeItem) => {
			let mappedChildren = [];

			if (item?.children?.length > 0) {
				mappedChildren = item.children.map(mapItemClick);
			}

			return { ...item, onClick: createOnItemClick(item), children: mappedChildren };
		},
		[createOnItemClick]
	);

	/**
	 * Reference to the props.options mapped to use the dropdown treeview.close function
	 */
	const closeEnabledOptions = useMemo(() => props?.options.map(mapItemClick) ?? [], [props.options, mapItemClick]);

	/**
	 * Handles the dropdown Selector opener/closer events.
	 *
	 * @param event The Keyboard/Mouse event
	 */
	const handleSelectClick = (event: React.FormEvent<HTMLDivElement>) => {
		if (props.disabled) return false;

		// Toggles the list to open, if not yet
		if (!isOpen) {
			toggleOpen();
		}
	};

	/**
	 * Manages the entering-field event.
	 * Opens or closes the popover at desired steps,
	 * be it Tab in, or Tab out.
	 *
	 * @param event The Keyboard/Mouse event
	 */
	const handleFocus = (event) => {
		// To capture only scenarios where it was not open yet,
		// And the shiftKey is not pressed (reverse command, for instance)
		if (event.nativeEvent.type === "focusout" && !event.shiftKey) return;

		handleSelectClick(event);
	};

	/**
	 * Handles the KeyDown events processed within the
	 * main TreeView component, the Input field.
	 *
	 * @param event The Keyboard/Mouse event
	 */
	const handleKeyDown = async (event) => {
		const openFieldKeys = ["Enter", "Space"];
		const closeFieldKeys = ["Escape", "Enter"];
		const clearFieldKeys = ["Backspace", "Delete"];

		// If the keys pressed were any of the field activator ones
		if (openFieldKeys.includes(event.key) && !isOpen && !props?.disabled) {
			handleSelectClick(event);
		}

		// Handling the backwards Tab field leave case-scenario
		if (event.shiftKey && event.key === "Tab" && isOpen && !props?.disabled) {
			toggleOpen();
			return;
		}

		// If the keys pressed were any of the field closing ones
		if (closeFieldKeys.includes(event.key) && isOpen && !props?.disabled) {
			toggleOpen();
			hideTooltip();
		}

		// If the key activated is the Return or Delete, and onClear function was provided.
		if (clearFieldKeys.includes(event.key) && !props.disabled) {
			if (typeof props?.onClear === "function") {
				props?.onClear();
			}
		}
	};

	/**
	 * Handles the KeyDown events processed within the
	 * opened TreeView popover.
	 *
	 * @param event The Keyboard/Mouse event
	 */
	const handleDropdownKeyDown = async (event) => {
		const closeKeys = ["Tab", "Escape"];
		const clearKeys = ["Backspace", "Delete"];

		if (props?.disabled) return;

		if (!isOpen) return;

		if (closeKeys.includes(event.key)) {
			toggleOpen();
			hideTooltip();
		}

		if (clearKeys.includes(event.key) && typeof props.onClear === "function") {
			props?.onClear();
			toggleOpen();
		}
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (divRef.current && !divRef.current.contains(event.target)) {
				toggleOpen();
				hideTooltip();
				event.stopPropagation();
			}
		}

		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen, divRef.current]);

	//////////////////////////////////////
	const [itemSelectedNew, setItemSelectedNew] = useState();
	const [focusedIndex, setFocusedIndex] = useState(0);
	const treeItemsRef = useRef([]);
	let isTruncated = false;
	const [tooltipText, setTooltipText] = useState("");

	//hide custom tooltip and show the default one to being capable of use it on another page
	const hideTooltip = () => {
		try {
			const toolTipDefault = document.getElementById("fluent-default-layer-host");
			const tooltipCallout = document.getElementById("my-tooltip");
			if (tooltipCallout) {
				tooltipCallout.style.display = "none";
			}
			if (toolTipDefault) {
				toolTipDefault.style.display = "block";
			}
		} catch {
			// Implement a handler for the catch scenario
		}
	};
	//hide tooltip on scroll

	window.addEventListener("scroll", () => {
		hideTooltip();
	});

	//hide tooltip on resize
	window.addEventListener("resize", () => {
		hideTooltip();
	});

	//handle focus of item, take the dimensions of the container and use it to resize the tooltip
	//Check if the item is truncated and activate the tooltip if it is focused by tabulation
	const handleFocusItem = (event) => {
		const target = event.target;
		const textDiv = target.querySelector(".ms-TooltipHost");
		const childDiv = textDiv.querySelector("div[hidden]");
		const tooltip = document.getElementById("my-tooltip");

		isTruncated = textDiv.scrollWidth > textDiv.clientWidth;
		setTooltipText(textDiv.innerText);

		const textDivRect = textDiv.getBoundingClientRect();
		const containerRect = target.getBoundingClientRect();

		const top = containerRect.top - textDivRect.height;
		const left = containerRect.left + containerRect.width / 2;

		tooltip.style.top = `${top}px`;
		tooltip.style.left = `${left}px`;
		tooltip.style.transform = `translate(-50%, -50%)`;
		tooltip.style.display = "block";

		const toolTipDefault = document.getElementById("fluent-default-layer-host");

		if (isTruncated) {
			document.getElementById("my-tooltip").hidden = false;
			if (toolTipDefault) {
				toolTipDefault.style.display = "none";
			}
		} else {
			hideTooltip();
		}

		setItemSelectedNew(event.target.innerText);
	};

	//manage how is focused by tabulation

	const handleKeyDownItem = (event) => {
		if (event.key === "ArrowDown") {
			event.preventDefault();
			setFocusedIndex((prevIndex) => (prevIndex + 1) % treeItemsRef.current.length);
		} else if (event.key === "ArrowUp") {
			event.preventDefault();
			setFocusedIndex((prevIndex) => (prevIndex - 1 + treeItemsRef.current.length) % treeItemsRef.current.length);
		} else if (event.key === "Escape") {
			hideTooltip();
		}
	};

	//adding event listeners to being capable of detect when is focused and when it has to display the tooltip.

	useEffect(() => {
		const treeItems = document.querySelectorAll('[role="treeitem"]');
		treeItems.forEach((item, index) => {
			item.setAttribute("tabindex", "0");
			item.addEventListener("focus", handleFocusItem);
			item.addEventListener("keydown", handleKeyDownItem);
			item.addEventListener("mouseenter", handleFocusItem);
			item.addEventListener("mouseleave", hideTooltip);
			treeItemsRef.current[index] = item;
		});

		return () => {
			treeItems.forEach((item) => {
				item.removeEventListener("focus", handleFocusItem);
				item.removeEventListener("keydown", handleKeyDownItem);
				item.removeEventListener("mouseenter", handleFocusItem);
				item.removeEventListener("mouseleave", hideTooltip);
			});
		};
	}, [isOpen]);

	useEffect(() => {
		if (treeItemsRef.current[focusedIndex]) {
			treeItemsRef.current[focusedIndex].focus();
		}
	}, [focusedIndex]);

	useEffect(() => {
		hideTooltip();
	}, []);

	//////////////////////////
	return (
		<div
			className={`tree-view-dropdown ${props?.inline ? "inline" : ""} ${props?.search ? "search" : ""}`}
			id={props.id ?? undefined}
			tabIndex={-1}
		>
			{/* Custom ToolTip to display at DropDown */}
			<div id="my-tooltip" className="my-tooltip">
				<div className="tooltip-content">
					{tooltipText}
					<p className="tooltip-subtext"></p>
				</div>
			</div>
			<div
				className={`tree-view-dropdown-selected-item form-control-type-with-icon ${
					props?.disabled ? "disabled" : ""
				} ${props?.error ? "error" : ""}`}
				// role="select"
				onClick={handleSelectClick}
				onFocus={handleFocus}
				onKeyDown={handleKeyDown}
				tabIndex={-1}
				title={props?.title ?? undefined}
				aria-label={props?.title ?? undefined}
				role="combobox"
				aria-required={props?.ariaRequired ?? undefined}
				aria-expanded={isOpen}
				aria-controls="tree-view-dropdown-options-id"
			>
				<div className="tree-view-dropdown-selected-item-text">
					<input
						required={props?.required ?? undefined}
						readOnly
						placeholder={props?.placeholder}
						value={item?.text ?? ""}
						alt={props?.alt ?? undefined}
					/>
				</div>
				<div className="tree-view-dropdown-selected-item-icon">
					<Icon name={`caret ${isOpen ? "up" : "down"}`} />
				</div>
			</div>
			{!props?.disabled && isOpen ? (
				<div className="tree-view-dropdown-options-overlay" tabIndex={-1}>
					<div
						ref={divRef}
						className="tree-view-dropdown-options-container"
						tabIndex={1}
						onKeyDown={handleDropdownKeyDown}
						id="tree-view-dropdown-options-id"
					>
						<TreeView
							aria-label={props.ariaLabel}
							data={closeEnabledOptions}
							onItemToggled={props.onItemToggled}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};
