/**
 * Redux config to manage the app
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { commentInitialState } from "../../models/slices/";
import { IStrikeNodesComments } from "../../contracts/models";

/**
 * Node Comments Management state layer, also named after a Redux Slice.
 *
 */
export const commentsSlice = createSlice({
	name: AppSlices.comments,
	initialState: commentInitialState,
	reducers: {
		setAllNodeComments: (state, action: PayloadAction<IStrikeNodesComments[]>) => {
			state.data.allNodeComments = action.payload;
		},
		setNodeLikeCount: (state, action: PayloadAction<number>) => {
			state.data.likeCount = action.payload;
		},
		setNodeLikeByUsers: (state, action: PayloadAction<string>) => {
			state.data.likeByUsers = action.payload;
		},
		setShowAllComments: (state) => {
			state.ui.isToggledComments = !state.ui.isToggledComments;
		},
		setToggleCommentForm: (state) => {
			state.ui.isToggledCommentForm = !state.ui.isToggledCommentForm;
		},
		setToggleCommentQuestionForm: (state) => {
			state.ui.isToggledCommentQuestionForm = !state.ui.isToggledCommentQuestionForm;
		},
		setIsNodeAlreadyLiked: (state, action: PayloadAction<boolean>) => {
			state.ui.isNodeAlreadyLiked = action.payload;
		},
		setFormSubmitting: (state, action: PayloadAction<boolean>) => {
			state.ui.submitting = action.payload;
		},
		setCommentText: (state, action: PayloadAction<string>) => {
			state.form.commentText = action.payload;
		},
		setNodeId: (state, action: PayloadAction<string>) => {
			state.form.nodeId = action.payload;
		},
		setNodeCommentsTotal: (state, action: PayloadAction<number>) => {
			state.data.nodeCommentsTotal = action.payload;
		},
		setAddCommentToList: (state, action: PayloadAction<IStrikeNodesComments>) => {
			const copyList = [...state.data.allNodeComments, action.payload];

			state.data.allNodeComments = copyList;
		},
		addLike: (state, action: PayloadAction<number>) => {
			state.data.likeCount = state.data.likeCount + 1;
			state.data.likeByUsers += (state.data.likeByUsers ? "," : "") + action.payload;
		},
		removeLike: (state, action: PayloadAction<number>) => {
			if (state.data.likeCount > 0) {
				state.data.likeCount = state.data.likeCount - 1;
			}
			const likesArray = state.data.likeByUsers.split(",").map((item) => parseInt(item));
			const index = likesArray.indexOf(action.payload);
			if (index !== -1) {
				likesArray.splice(index, 1);
			}
			state.data.likeByUsers = likesArray.join(",");
		},
		setReplyItemExpandedById: (state, action: PayloadAction<string | number>) => {
			state.ui.replyExpandedId = action.payload;
		},
		setBodySourceMode: (state, action: PayloadAction<boolean>) => {
			state.form.bodySourceMode = action.payload;
		},
		setCommentsToShow: (state, action: PayloadAction<number>) => {
			state.data.commentsToShow = action.payload;
		},
		resetCommentsToShow: (state) => {
			state.data.commentsToShow = commentInitialState.data.commentsToShow;
		},
		updateAcceptedAnswer: (
			state,
			action: PayloadAction<{ answerId: number; acceptedFlagProp: string; acceptedFlagValue: boolean }>
		) => {
			const { answerId, acceptedFlagProp, acceptedFlagValue } = action.payload;
			const answerToUpdate = state.data.allNodeComments.find((answer) => answer.CommentId === answerId);
			if (answerToUpdate) {
				answerToUpdate[acceptedFlagProp] = acceptedFlagValue;
			}
		},
		setEditingIds: (state, action: PayloadAction<number[]>) => {
			state.data.editingIds = action.payload;
		},
		resetEditingIds: (state) => {
			state.data.editingIds = commentInitialState.data.editingIds;
		},
		setCommentInList: (state, action: PayloadAction<IStrikeNodesComments>) => {
			const copyList = [...state.data.allNodeComments];

			// Finds and updates the entry in the list
			for (let commentIndex = 0; copyList.length > commentIndex; commentIndex++) {
				if (copyList[commentIndex].CommentId === action.payload.CommentId) {
					copyList[commentIndex].Title = action.payload.Title;
					break;
				}
			}

			state.data.allNodeComments = copyList;
		}
	}
});

export const {
	setNodeLikeCount,
	setNodeLikeByUsers,
	setAllNodeComments,
	setShowAllComments,
	setToggleCommentForm,
	setToggleCommentQuestionForm,
	setIsNodeAlreadyLiked,
	setCommentText,
	setNodeId,
	setNodeCommentsTotal,
	setAddCommentToList,
	addLike,
	removeLike,
	setReplyItemExpandedById,
	setFormSubmitting,
	setBodySourceMode,
	setCommentsToShow,
	resetCommentsToShow,
	updateAcceptedAnswer,
	setEditingIds,
	resetEditingIds,
	setCommentInList
} = commentsSlice.actions;

export default commentsSlice.reducer;
